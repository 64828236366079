import React, { type FC, useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@components/ui/table';
import { IconArrowLeft, IconClipboardList, IconHeadset } from '@tabler/icons-react';
import ContactInfoSheetComponent from '@views/Dashboard/Components/ContactInfoSheet.component';
import LayoutView from '@views/Layout/Layout.view';
import UICardComponent from '@views/Layout/Components/UICard.component';
import { Button } from '@components/ui/button';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DispositionDefaultAction } from '@views/types/disposition-mapping.type';
import { DashboardDetailsViewModel } from '@views/Dashboard/Components/DashboardDetails.viewmodel';
import moment from 'moment-timezone';
import { DashboardViewmodel } from '@views/Dashboard/Dashboard.viewmodel';
import { type OutletContextProps } from '@/src/components/RoutesLayout';
import { PrettyMetricActions } from '@views/types/metric-action.type';
import UILoader from '@/src-v2/Presentation/components/shared/UILoader';
import { featuresStore } from '@/src/components/app/FeaturesProvider';
import PaginatorControlsComponent from '@/src-v2/Presentation/components/shared/PaginatorControls.component';
import useScreenSize from '@/src/core/hooks/useScreenSize';
import RecordingPlayerComponent from '@views/Dashboard/Components/RecordingPlayer.component';
import { PrivateRoutes } from '@/src/constants/routesConnect';

const DashboardDetailsView: FC = () => {
  const {
    callSessionData,
    contactInformation,
    loading,
    sdrAudio,
    agentAudio,
    sdrAudioDuration,
    agentAudioDuration,
    openedModal,
    setOpenedRecordingModal,
    getContactInformation,
    getDetailsInfo,
    getRecordings,
    setSdrAudio,
    setAgentAudio,
    paginationData,
  } = DashboardDetailsViewModel();

  const {
    featuresState: { callRecordings },
  } = useContext(featuresStore);
  const [pageNumber, setPageNumber] = useState(1);
  const { timezone } = DashboardViewmodel();
  const navigate = useNavigate();
  const { callSessionId: sessionId } = useParams();
  const bolderTextStyle = 'text-sm font-bold text-app-gray-500';
  const lightTextStyle = 'text-sm text-app-gray-400';
  const cellStyle = 'text-center text-wrap';
  const screenSize = useScreenSize();
  const proportionConstant: number = Math.ceil(screenSize.height - screenSize.height / 2.5);
  const {
    userTypeAdmin,
    userSession: { isSuperAdmin: userSuperAdmin },
  } = useOutletContext<OutletContextProps>();
  const recordings = Boolean(callRecordings) && Boolean(userSuperAdmin);
  function displayDispositionByAbbreviation(abbreviation: string): string {
    if (abbreviation === 'default_action') {
      return 'Default Action';
    }
    if (abbreviation in DispositionDefaultAction) {
      return DispositionDefaultAction[abbreviation as keyof typeof DispositionDefaultAction];
    }

    return 'Default Action';
  }

  function displayPrettyAction(rawAction: string): string {
    if (rawAction in PrettyMetricActions) {
      return PrettyMetricActions[rawAction as keyof typeof PrettyMetricActions];
    }
    return 'Default Action';
  }

  function returnToDashboard(): void {
    const lastPage = sessionStorage.getItem('lastDashboardPage') ?? '1';

    navigate(`/${PrivateRoutes.DASHBOARD_V2}?page=${lastPage}`, { replace: true });
  }

  const handlePrev = (): void => {
    setPageNumber((last) => last - 1);
    getDetailsInfo(String(sessionId), pageNumber);
  };

  const handleNext = (): void => {
    setPageNumber((last) => last + 1);
    getDetailsInfo(String(sessionId), pageNumber);
  };

  useEffect(() => {
    getDetailsInfo(String(sessionId), pageNumber);
  }, [pageNumber]);

  const {
    session: { sdrName, sdrId, callSessionId, organizationName, organizationId },
    calls,
  } = callSessionData;
  return (
    <LayoutView>
      <UICardComponent title="Dashboard Details" showTitle={false} width="[full]" height="[100%]">
        <h1 className="mx-3 font-extrabold text-lg">Call Session Details</h1>
        <header className="flex justify-between items-center px-4">
          <div className="grid grid-cols-3 gap-1 mb-4">
            <div>
              <h4 className={bolderTextStyle}>SDR: {loading ? 'Loading...' : sdrName}</h4>
              <h5 className={lightTextStyle}>{sdrId}</h5>
            </div>
            <div>
              <h4 className={bolderTextStyle}>Call Session Id:</h4>
              <h5 className={lightTextStyle}>{loading ? 'Loading...' : callSessionId}</h5>
            </div>
            <div>
              <h4 className={bolderTextStyle}>{`Organization: ${loading ? 'Loading...' : organizationName}`}</h4>
              <h5 className={lightTextStyle}>{organizationId}</h5>
            </div>
          </div>

          <div className="ml-auto">
            <Button onClick={returnToDashboard}>
              <IconArrowLeft /> Back to Dashboard
            </Button>
          </div>
        </header>

        {loading ? (
          <div>
            <UILoader message={'Loading session details, please wait...'} size="4/5" />
          </div>
        ) : (
          <div
            style={{ height: screenSize.height > 700 ? `${proportionConstant}px` : '100%' }}
            className={`${
              screenSize.height < 700 ? 'max-h-[430px] min-[1500px]:max-h-[430px] min-[1024px]:max-h-[375px]' : ''
            } overflow-y-scroll`}
          >
            <Table>
              <TableHeader className={`${cellStyle} bg-secondary`}>
                {userTypeAdmin && <TableCell>Agent</TableCell>}
                <TableCell>Action</TableCell>
                <TableCell>Disposition</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Contact List</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Timestamp</TableCell>
                {recordings && <TableCell>Recordings</TableCell>}
              </TableHeader>
              <TableBody>
                {calls.map((callSession, index) => {
                  const {
                    agentId,
                    agentName,
                    action,
                    disposition,
                    contactListName,
                    contactListId,
                    contactId,
                    contactName,
                    callId,
                    createdAt: callTimestamp,
                  } = callSession;

                  return (
                    <TableRow key={index} className={'items-center'}>
                      {userTypeAdmin && (
                        <TableCell className={cellStyle}>
                          {agentName}
                          <br />
                          <h3 className="text-app-gray-300">{agentId}</h3>
                        </TableCell>
                      )}
                      <TableCell className={cellStyle}>{displayPrettyAction(action)}</TableCell>
                      <TableCell className={cellStyle}>{displayDispositionByAbbreviation(disposition)}</TableCell>
                      <TableCell className={`${cellStyle} min-w-40 min-[1400px]:w-72`}>
                        <ContactInfoSheetComponent
                          contact={contactInformation}
                          triggerElement={
                            <a
                              className="underline text-app-blue-light"
                              onClick={() => {
                                getContactInformation(contactId);
                              }}
                            >
                              {contactName}
                            </a>
                          }
                          triggerButtonStyles="min-w-40 min-[1100px]:w-72 cursor-pointer"
                        />
                      </TableCell>
                      <TableCell className={cellStyle}>
                        {contactListName}
                        <br />
                        <h3 className="text-app-gray-300">{contactListId}</h3>
                      </TableCell>
                      <TableCell className={`${cellStyle}`}>
                        <ContactInfoSheetComponent
                          contact={contactInformation}
                          triggerElement={
                            <IconClipboardList
                              color="#0095D4"
                              onClick={() => {
                                getContactInformation(contactId);
                              }}
                            />
                          }
                          triggerButtonStyles="flex justify-center max-w-12 cursor-pointer"
                        />
                      </TableCell>
                      <TableCell className={cellStyle}>{moment(callTimestamp).tz(timezone).format('llll')}</TableCell>
                      {recordings && (
                        <TableCell className={`${cellStyle}`}>
                          <RecordingPlayerComponent
                            opened={openedModal}
                            setOpenedModal={setOpenedRecordingModal}
                            sdrAudio={sdrAudio}
                            sdrAudioDuration={sdrAudioDuration}
                            agentAudio={agentAudio}
                            agentAudioDuration={agentAudioDuration}
                            setSdrAudio={setSdrAudio}
                            setAgentAudio={setAgentAudio}
                            trigger={
                              <IconHeadset
                                color="#0095D4"
                                onClick={() => {
                                  void getRecordings(callId);
                                }}
                              />
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
        {!loading && (
          <div className="flex min-w-full justify-end">
            <PaginatorControlsComponent
              pageNumber={paginationData.pageNumber}
              totalPages={paginationData.totalPages}
              totalCount={paginationData.totalCount}
              pageSize={paginationData.pageSize}
              onPrevPage={handlePrev}
              onNextPage={handleNext}
            />
          </div>
        )}
      </UICardComponent>
    </LayoutView>
  );
};

export default DashboardDetailsView;

import React, { StrictMode } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import './index.css';
import UserProvider from './components/app/UserProvider';
import { LoggerProvider } from './components/app/LoggerProvider';
import { config } from './config';
import TagManager from 'react-gtm-module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { createRoot } from 'react-dom/client';
import Login from './pages/Login/Login';
import { PrivateRoutes, PublicRoutes } from './constants/routesConnect';
import RoutesLayout from './components/RoutesLayout';
import DialerSDR from './pages/Dialer/DialerSDR';
import Settings from './pages/Settings/Settings';
import DialerAgent from './pages/Dialer/DialerAgent';
import Management from './pages/Management/Management';
import Summary from './pages/Dashboard/Summary';
import PasswordChange from './pages/Login/PasswordChange';
import Contacts from './pages/Contacts/Contacts';
import Voice from './pages/Voice/Voice';
import Script from './pages/Script/Script';
import CallerIdContainer from './pages/Settings/CallerId/CallerIdContainer';
import { Outreach } from './pages/Settings/Outreach/Outreach';
import { SalesForceView } from '../src-v2/Presentation/Views/Settings/Integrations/Salesforce/SalesforceConfig.view'
import OutreachMappingsView from '../src-v2/Presentation/Views/Settings/Integrations/Outreach/Mappings/OutreachMappings.view';
import { Hubspot } from './pages/Settings/Hubspot/Hubspot';
import HubspotMappingsView from '../src-v2/Presentation/Views/Settings/Integrations/Hubspot/Mappings/HubspotMappings.view';
import { HubspotCustomApp } from './pages/Settings/HubspotApp/HubspotCustomApp';
import { FeatureFlags, FeaturesProvider } from './components/app/FeaturesProvider';
import AuthGuard from './guards/auth.guard';
import RoleGuard from './guards/role.guard';
import { Roles } from './types/Roles';
import UIToastContainer from './core/ui/UIToast';
import AccountView from '../src-v2/Presentation/Views/Settings/Account/Account.view';
import { FeatureFlagGuard } from './guards/feature-flag.guard'
import { Toaster } from "../src-v2/Presentation/Common/Components/ui/toaster";
import SessionsView from "../src-v2/Presentation/Views/Settings/ActiveSessions/Sessions.view";
import AgentsCallTable from "../src-v2/Presentation/Views/Settings/ActiveSessions/AgentCalls/AgentsCall";
import Maintenance from "./pages/Maintenance/Maintenance";
import { AuthProvider } from './components/app/AuthProvider';
import DashboardView from "../src-v2/Presentation/Views/Dashboard/Dashboard.view";
import DashboardDetailsView from "../src-v2/Presentation/Views/Dashboard/Components/DashboardDetails.view";
const window = globalThis as any;
const document = window.document;

library.add(fas)
const tagManagerArgs = {
  gtmId: config.analytics.tagManager
}
TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <>
      <UserProvider>
          <LoggerProvider>
          <AuthProvider>
        <FeaturesProvider>
          <>
            <UIToastContainer />
            <Toaster />
            <RouterProvider router={JSXRouter} />
          </>
        </FeaturesProvider>
          </AuthProvider>
          </LoggerProvider>
      </UserProvider>
    </>
  )
}

export const JSXRouter = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path={PublicRoutes.LOGIN} element={<Login />} />
    <Route path={PublicRoutes.PASSWORD_CHANGE} element={<PasswordChange />} />
    <Route path={PublicRoutes.PASSWORD_RECOVER} element={<Maintenance />} />
    <Route path={'*'} element={<AuthGuard />}>
      <Route element={<RoutesLayout />}>
        <Route path={PrivateRoutes.HOME}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <DialerSDR />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.DIALER}
          element={
            <RoleGuard roles={[Roles.AGENT]}>
              <DialerAgent />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.CONTACTS}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Contacts />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.VOICE}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Voice />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.SCRIPT}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Script />
            </RoleGuard>
          }
        />

        <Route path={PrivateRoutes.DASHBOARD_V2}
          element={
            <RoleGuard roles={[Roles.SDR,Roles.ADMIN]}>
                <DashboardView/>
            </RoleGuard>
          }
        />

          <Route path={`${PrivateRoutes.DASHBOARD_DETAILS}/:callSessionId`}
                 element={
                     <RoleGuard roles={[Roles.SDR,Roles.ADMIN]}>
                         <DashboardDetailsView/>
                     </RoleGuard>
                 }
          />

        <Route path={`${PrivateRoutes.SUMMARY}/:callsessionid`}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Summary />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.MANAGEMENT}
          element={
            <RoleGuard roles={[Roles.ADMIN]}>
              <Management />
            </RoleGuard>
          }
        />
        <Route path={PrivateRoutes.SETTINGS}
          element={
            <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
              <Settings />
            </RoleGuard>
          }
        >
          <Route path={`${PrivateRoutes.SETTINGS_ACCOUNT_V2}`}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <FeatureFlagGuard features={[FeatureFlags.ACCOUNT_SETTINGS]}>
                  <AccountView />
                </FeatureFlagGuard>
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_PHONE}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <CallerIdContainer />
              </RoleGuard>
            }
          />
           <Route path={PrivateRoutes.SETTINGS_ACTIVE_SESSIONS} element={
        <RoleGuard roles={[Roles.ADMIN]}>
          <SessionsView/>
        </RoleGuard>
      }/>

      <Route path={PrivateRoutes.SETTINGS_ACTIVE_SESSIONS + "/:callSessionId"} element={
        <RoleGuard roles={[Roles.ADMIN]}>
          <AgentsCallTable/>
        </RoleGuard>
      }/>

          <Route path={PrivateRoutes.SETTINGS_OUTREACH}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <Outreach />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_SALES_FORCE}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <SalesForceView />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_OUTREACH_MAPPINGS}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <OutreachMappingsView />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <Hubspot />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT_MAPPINGS}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <HubspotMappingsView />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT_CUSTOM}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <HubspotCustomApp />
              </RoleGuard>
            }
          />
          <Route path={PrivateRoutes.SETTINGS_HUBSPOT_CUSTOM_MAPPINGS}
            element={
              <RoleGuard roles={[Roles.SDR, Roles.ADMIN]}>
                <HubspotMappingsView />
              </RoleGuard>
            }
          />
        </Route>
        <Route path="*" element={<Navigate replace to={PrivateRoutes.HOME} />} />
      </Route>
      <Route path="*" element={<Navigate replace to={PublicRoutes.LOGIN} />} />
    </Route>
    <Route path="*" element={<Navigate replace to={PublicRoutes.LOGIN} />} />
  </>
));

const rootNode = document.getElementById('root');

createRoot(rootNode).render(
  <StrictMode>
    <App />
  </StrictMode>)

import routes from "../../constants/routes";
import axios from "axios";
import { SalesforceApp } from "../../types/SalesforceTypes";

type ResponseProps = {
  salesforceConfig?: SalesforceApp;
  error?: { message: string };
};

const saveSalesforceConfigAsync = async (
  orgId: string,
  userId: string,
  salesforceAppData: Pick<
    SalesforceApp,
    | "id"
    | "appId"
    | "accessKey"
    | "accessSecret"
    | "url"
    | "organizationId"
    | "userId"
    | "callbackUrl"
  >
): Promise<ResponseProps> => {
  try {
    const saveSalesforceConfiguration = await axios.post<SalesforceApp>(
      `${routes.get.salesforce.getSalesforceConfiguration}/${orgId}/${userId}`,
      salesforceAppData
    );
    return { salesforceConfig: saveSalesforceConfiguration.data };
  } catch (e) {
    return { error: { message: "Error saving the Salesforce Configuration" } };
  }
};

export default saveSalesforceConfigAsync;

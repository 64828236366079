import { useState, useContext, useEffect } from 'react';
import { type Account } from '@/src/pages/Settings/Shared/Entities/Account';
import { UserProviderContext } from '@/src/components/app/UserProvider';
import { SalesforceUseCase } from '@/src-v2/Domain/UseCase/salesforce/salesforceUsecase';

export interface SalesforceViewModel {
  accounts: Account[];
  loading: boolean;
  configuring: boolean;
  stateMessage: string;
  loadingMessage: string;
  handleConnect: () => Promise<{ status: number; message: string }>;
  handleCancel: () => void;
  removeSalesforceUser: (accountId: string) => Promise<{ status: number; message: string }>;
}

export default function useSalesforceViewModel(): SalesforceViewModel {
  const { currentUser } = useContext(UserProviderContext);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [state, setState] = useState({
    configuring: false,
    loading: true,
    stateMessage: 'Use button above to connect Salesforce.',
    loadingMessage: 'Loading linked accounts...',
  });
  let accountInterval: ReturnType<typeof setInterval> | null = null;

  const { getSalesforceAccounts, salesforceAuth, deleteAccount } = SalesforceUseCase();

  const handleConnect = async (): Promise<{ status: number; message: string }> => {
    try {
      const { status, data, success } = await salesforceAuth(currentUser.organizationId, currentUser.userId);
      if (success === true && data !== null && status === 201) {
        window.open(data, 'salesforcePopup', `width=${500},height=${500}`);
        startAccountPolling();
        setState((prevState) => ({ ...prevState, configuring: true }));
        return { status: 201, message: 'Salesforce connected' };
      }
    } catch (err) {
      return { status: 500, message: "Can't configure Salesforce right now, please try again later." };
    }
    return { status: 500, message: 'Unexpected error.' };
  };

  const handleCancel = (): void => {
    setState((prevState) => ({
      ...prevState,
      configuring: false,
      stateMessage: 'Use button above to connect Salesforce.',
    }));
    stopAccountPolling();
  };

  const getAccounts = async (): Promise<{ status: number; message: string }> => {
    try {
      setAccounts([]);
      const { status, data, success } = await getSalesforceAccounts(currentUser.userId, currentUser.organizationId);
      if (success === false) {
        stopAccountPolling();
        setState((prevState) => ({
          ...prevState,
          configuring: false,
          loading: false,
          stateMessage: 'No Salesforce accounts configured.',
        }));
        return { status: 500, message: 'No Salesforce accounts configured.' };
      } else if (status === 202) {
        setState((prevState) => ({
          ...prevState,
          configuring: true,
          stateMessage: 'Configuration in process.',
        }));
        return { status: 202, message: 'Salesforce syncing in progress.' };
      } else if (status === 204) {
        stopAccountPolling();
        setState((prevState) => ({
          ...prevState,
          configuring: false,
          stateMessage: 'No Salesforce accounts configured.',
        }));
        return { status: 204, message: 'No Salesforce accounts configured.' };
      } else if (status === 200 && data != null) {
        const amount = data.length;
        setState((prevState) => ({
          ...prevState,
          loading: false,
          configuring: false,
          stateMessage: `You have ${amount} ${amount > 1 ? 'account' : 'accounts'} linked.`,
        }));
        setAccounts(data);
        stopAccountPolling();
        return { status: 200, message: `Salesforce linked with ${amount} account(s).` };
      }
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
      stopAccountPolling();
      return { status: 500, message: 'Error fetching Salesforce accounts.' };
    }
    return { status: 500, message: 'Unexpected error.' };
  };

  const startAccountPolling = (): void => {
    stopAccountPolling();
    accountInterval = setInterval(() => {
      void getAccounts();
    }, 5000);
  };

  const stopAccountPolling = (): void => {
    if (accountInterval !== null) {
      clearInterval(accountInterval);
      accountInterval = null;
    }
  };

  const removeSalesforceUser = async (accountId: string): Promise<{ status: number; message: string }> => {
    try {
      const { success, status } = await deleteAccount(currentUser.organizationId, currentUser.userId, accountId);
      if (success === true && status === 204) {
        setAccounts((prev) => prev.filter((elem) => elem.id !== accountId));
        return { status: 204, message: 'Salesforce account removed successfully.' };
      } else {
        return { status: 500, message: 'Failed to remove Salesforce account.' };
      }
    } catch (error) {
      console.error('Error removing Salesforce account:', error);
      return { status: 500, message: 'Error removing Salesforce account.' };
    }
  };

  useEffect(() => {
    void getAccounts();
    return () => {
      stopAccountPolling();
    };
  }, []);

  return {
    accounts,
    loading: state.loading,
    configuring: state.configuring,
    stateMessage: state.stateMessage,
    loadingMessage: state.loadingMessage,
    handleConnect,
    handleCancel,
    removeSalesforceUser,
  };
}

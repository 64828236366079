import React, { useEffect, useRef, useState } from 'react';
import LayoutView from '../../Layout/Layout.view';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import TotalCalls from '../../assets/icons/01-totalcalls.svg';
import Answered from '@views/assets/icons/02-answered.svg';
import Transferred from '@views/assets/icons/03-transferred (2).svg';
import GKConversation from '@views/assets/icons/04-gkconversation (1).svg';
import LVoicemail from '@views/assets/icons/05-leftvoicemail.svg';
import WrongNumber from '@views/assets/icons/06-wrongnumber.svg';
import PhoneTreeNoConnection from '@views/assets/icons/08-ptnc.svg';
import NoLongerWithTheCompany from '@views/assets/icons/07-nlwc.svg';
import ReferredToAnotherContact from '@views/assets/icons/09-referredtoanothercontact.svg';
import SetAppointments from '@views/assets/icons/setappointment.svg';
import FuegoLeads from '@views/assets/icons/fuegoleads.svg';
import ContactsIcon from '@views/assets/icons/contacts.svg';
import HubspotIcon from '@views/assets/icons/contacts-hubspot.svg';
import OutreachIcon from '@views/assets/icons/contacts-outreach.svg';
import { ChevronRight } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { SummaryViewModel } from '@views/Dashboard/Components/Summary.viewmodel';
import { DashboardViewmodel } from '@views/Dashboard/Dashboard.viewmodel';
import UILoader from '@/src-v2/Presentation/components/shared/UILoader';
import { IconDownload } from '@tabler/icons-react';

const SummaryView: React.FC = () => {
  const navigate = useNavigate();
  const { callsessionid: sessionId } = useParams();
  const { getSummaryData, getPercentage } = SummaryViewModel();
  const { generateReport } = DashboardViewmodel();
  const [loading, setLoading] = useState<boolean>(true);
  const [summaryData, setSummaryData] = useState<{
    formattedDate?: string;
    formattedTime?: string;
    callSessionId?: string;
    userAvatar?: string;
    userName?: string;
    userOrganization?: string;
    contactListName?: string;
    contactListType?: string;
    totalContacts?: number;
    metrics?: {
      called?: number;
      answered?: number;
      transferred?: number;
    };
    dispositions?: {
      gatekeeper_conversation?: number;
      left_vm?: number;
      wrong_number?: number;
      phone_tree_no_connection?: number;
      no_longer_with_company?: number;
      referred_another_contact?: number;
      decision_maker_conversation?: number;
      set_appointment?: number;
      opt_out?: number;
    };
    dialToConnect?: number;
    fuegoLead?: number;
  }>({
    metrics: {
      called: 0,
    },
    dispositions: {
      decision_maker_conversation: 0,
    },
  });

  useEffect(() => {
    const fetchCallSessionData = async (): Promise<void> => {
      if (sessionId != null) {
        setLoading(true); // Inicia el proceso de carga
        try {
          const data = await getSummaryData(sessionId);
          if (data != null) setSummaryData(data);
        } catch (error) {
          console.error('Error fetching session data:', error);
        } finally {
          setLoading(false); // Finaliza el proceso de carga
        }
      }
    };

    fetchCallSessionData().catch((e) => {
      console.error('Unexpected error:', e);
      setLoading(false);
    });
  }, [sessionId]);

  const getContactListIcon = (type: string): string => {
    switch (type) {
      case 'CSV':
        return ContactsIcon as unknown as string;
      case 'OUTREACH':
        return OutreachIcon as unknown as string;
      case 'HUBSPOT':
        return HubspotIcon as unknown as string;
      default:
        return ContactsIcon as unknown as string; // Icono por defecto
    }
  };

  return (
    <LayoutView>
      {loading ? (
        <UILoader message={'Loading call sessions, please wait...'} />
      ) : (
        <div className="min-h-screen p-6 text-black">
          {/* Breadcrumbs Section */}
          <nav className="flex items-center space-x-1 text-lg text-muted-foreground mb-4">
            {/* Dashboard Breadcrumb */}
            <button
              type="button"
              className="hover:text-white text-blue-700"
              onClick={() => {
                navigate('/dashboard-v2');
              }}
            >
              Dashboard
            </button>
            <ChevronRight className="h-4 w-4 text-blue-700" />
            {/* Current Page */}
            <span className="text-white font-extrabold">Summary</span>
          </nav>

          {/* Header Section */}
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-white">Session Summary</h1>
            <div className="flex items-center space-x-0">
              <div className="text-right">
                <div className="flex items-center">
                  <p className="px-4 py-2 px-4 py-2 border border-gray-400 rounded-l border-r-none bg-white text-gray-600 bg-white text-gray-600 font-bold ">
                    {summaryData?.formattedDate}
                  </p>
                  <p className="px-4 py-2 border border-gray-400 rounded-tr rounded-br border-l-none bg-white text-gray-600 font-bold mr-4">
                    {summaryData?.formattedTime}
                  </p>

                  <button
                    className=" bg-white text-blue-500 px-4 py-2 rounded hover:bg-blue-600 hover:text-white"
                    onClick={() => {
                      generateReport(summaryData?.callSessionId as string, 'compact');
                    }}
                  >
                    <div className="flex items-center space-x-2">
                      <IconDownload stroke={2} />
                      <span>Download Report</span>
                    </div>
                  </button>
                </div>
                <p className="text-white mt-1 text-left text-sm">Session ID: {summaryData?.callSessionId}</p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
            <div className="bg-gray-100 shadow-md p-4 rounded-lg">
              {/* User Info Section */}
              <div className="flex bg-white p-6 rounded shadow mb-6">
                <div className="flex items-center space-x-4">
                  <img src={summaryData?.userAvatar} alt="User Avatar" className="w-20 h-20 rounded-full" />
                  <div>
                    <h2 className="text-lg font-bold">{summaryData?.userName}</h2>
                    <p className="text-xs text-gray-500 mb-2">USER</p>
                    <h3 className="font-bold">{summaryData?.userOrganization}</h3>
                    <p className="text-xs text-gray-500 mb-2">ORGANIZATION</p>
                    <h3 className="font-bold">{summaryData?.contactListName}</h3>
                    <p className="text-xs text-gray-500">CONTACT LIST NAME</p>
                  </div>
                </div>
                <div className="ml-auto text-right">
                  <p className=" text-3xl font-bold">{summaryData?.totalContacts}</p>
                  <p className="text-xs text-gray-500 mb-2">CONTACTS</p>
                  <img
                    src={getContactListIcon(summaryData?.contactListType as string)}
                    alt={summaryData.contactListType}
                    className="w-20 h-20"
                  />
                </div>
              </div>

              {/* Metrics Section */}
              <div>
                <div className="grid grid-cols-3 gap-6 mb-6">
                  <MetricCard
                    title="Total Calls"
                    value={summaryData?.metrics?.called ?? 0}
                    icon={<img src={TotalCalls as unknown as string} alt="Total Calls" />}
                    percentage={undefined}
                  />
                  <MetricCard
                    title="Answered"
                    value={summaryData?.metrics?.answered ?? 0}
                    icon={<img src={Answered as unknown as string} alt="Answered Calls" />}
                    percentage={undefined}
                  />
                  <MetricCard
                    title="Transferred"
                    value={summaryData?.metrics?.transferred ?? 0}
                    icon={<img src={Transferred as unknown as string} alt="Transferred Calls" />}
                    percentage={undefined}
                  />
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <MetricCard
                    title="GK Conversations"
                    value={summaryData?.dispositions?.gatekeeper_conversation ?? 0}
                    icon={<img src={GKConversation as unknown as string} alt="GK Conversations" />}
                    percentage={getPercentage(
                      summaryData?.dispositions?.gatekeeper_conversation ?? 0,
                      summaryData?.metrics?.called ?? 1,
                    ).toFixed(2)}
                  />
                  <MetricCard
                    title="Left a Voicemail"
                    value={summaryData?.dispositions?.left_vm ?? 0}
                    icon={<img src={LVoicemail as unknown as string} alt="Left a Voicemail" />}
                    percentage={getPercentage(
                      summaryData?.dispositions?.left_vm ?? 0,
                      summaryData?.metrics?.called ?? 1,
                    ).toFixed(2)}
                  />
                  <MetricCard
                    title="Wrong Number"
                    value={summaryData?.dispositions?.wrong_number ?? 0}
                    icon={<img src={WrongNumber as unknown as string} alt="Wrong Number" />}
                    percentage={getPercentage(
                      summaryData?.dispositions?.wrong_number ?? 0,
                      summaryData?.metrics?.called ?? 1,
                    ).toFixed(2)}
                  />
                  <MetricCard
                    title="Phone Tree No Connection"
                    value={summaryData?.dispositions?.phone_tree_no_connection ?? 0}
                    icon={<img src={PhoneTreeNoConnection as unknown as string} alt="Phone Tree No Connection" />}
                    percentage={getPercentage(
                      summaryData?.dispositions?.phone_tree_no_connection ?? 0,
                      summaryData?.metrics?.called ?? 1,
                    ).toFixed(2)}
                  />
                  <MetricCard
                    title="No Longer with the Company"
                    value={summaryData?.dispositions?.no_longer_with_company ?? 0}
                    icon={<img src={NoLongerWithTheCompany as unknown as string} alt="No Longer with the Company" />}
                    percentage={getPercentage(
                      summaryData?.dispositions?.no_longer_with_company ?? 0,
                      summaryData?.metrics?.called ?? 1,
                    ).toFixed(2)}
                  />
                  <MetricCard
                    title="Referred to Another Contact"
                    value={summaryData?.dispositions?.referred_another_contact ?? 0}
                    icon={<img src={ReferredToAnotherContact as unknown as string} alt="Referred to Another Contact" />}
                    percentage={getPercentage(
                      summaryData?.dispositions?.referred_another_contact ?? 0,
                      summaryData?.metrics?.called ?? 1,
                    ).toFixed(2)}
                  />
                </div>
              </div>
            </div>

            <div className="bg-gray-100 shadow-md p-4 rounded-lg">
              {/* Dial-to-Connect Section */}
              <div className="bg-white p-6 rounded shadow">
                <h3 className="text-2xl font-bold mb-4">Transfer Outcomes</h3>
                <div className="flex justify-between items-center gap-12">
                  <div className="w-1/3">
                    <p className="text-4xl font-bold text-green-500 text-center">{summaryData?.dialToConnect}</p>
                    <p className="text-gray-500 text-center">Dial to Connect Ratio</p>
                    <div className="mt-4">
                      <DialToConnectChart ratio={summaryData?.dialToConnect ?? 0} />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-8 w-2/3">
                    <OutcomeBadge
                      label="Decision Maker Conversation"
                      count={summaryData?.dispositions?.decision_maker_conversation ?? 0}
                      color="green"
                    />
                    <OutcomeBadge
                      label="Gatekeeper Conversation"
                      count={summaryData?.dispositions?.gatekeeper_conversation ?? 0}
                      color="blue"
                    />
                    <OutcomeBadge
                      label="Referred to Another Contact"
                      count={summaryData?.dispositions?.referred_another_contact ?? 0}
                      color="yellow"
                    />
                    <OutcomeBadge label="Opt Out" count={summaryData?.dispositions?.opt_out ?? 0} color="red" />
                  </div>
                </div>
              </div>

              {/* Summary Section */}
              <div className="flex justify-between items-center mt-8 gap-8">
                {/* Fuego Leads */}
                <div className="bg-gradient-to-bl from-red-950 via-red-700 to-red-500 text-white p-6 rounded shadow w-full max-w-md grid grid-rows-3">
                  <div className="flex items-center justify-center">
                    <img
                      src={FuegoLeads as unknown as string}
                      alt="Fuego Leads"
                      className="w-20 h-20 border rounded-full border-white p-3 bg-white"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <p className="text-6xl md:text-8xl lg:text-9xl font-bold">{summaryData?.fuegoLead}</p>
                  </div>
                  <div className="flex items-center justify-center">
                    <h3 className="text-xl font-bold">Fuego Leads</h3>
                  </div>
                </div>

                {/* Set Appointments */}
                <div className="bg-green-500 text-white p-6 rounded shadow w-full max-w-md grid grid-rows-3">
                  <div className="flex items-center justify-center">
                    <img
                      src={SetAppointments as unknown as string}
                      alt="Set Appointments"
                      className="w-20 h-20 border rounded-full border-white p-3 bg-white"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <p className="text-6xl md:text-8xl lg:text-9xl font-bold">
                      {summaryData?.dispositions?.set_appointment ?? 0}
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <h3 className="text-xl font-bold">Set Appointments</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LayoutView>
  );
};

interface MetricCardProps {
  title: string;
  value: number;
  percentage: string | undefined;
  icon: any;
}
const MetricCard: React.FC<MetricCardProps> = ({ title, value, icon, percentage }) => {
  return (
    <div className="bg-white p-4 rounded shadow flex flex-col items-center space-y-2">
      <div
        className={`w-16 h-16 rounded-full flex items-center justify-center p-2 ${
          title === 'Transferred' ? 'bg-green-500' : 'bg-gray-100'
        }`}
      >
        {icon}
      </div>
      <div className="text-center">
        {percentage !== null && percentage !== undefined && percentage !== '0' && (
          <p className="text-blue-600 text-lg font-bold truncate">{percentage === '0.00' ? 0 : percentage}%</p>
        )}
        <p className="font-bold text-2xl text-gray-800">{value}</p>
        <p className="text-gray-500 text-sm md:text-base break-words">{title}</p>
      </div>
    </div>
  );
};

interface OutcomeBadgeProps {
  label: string;
  count: number;
  color: string;
}

const OutcomeBadge: React.FC<OutcomeBadgeProps> = ({ label, count, color }) => {
  const colors = new Map<string, string>([
    ['green', 'bg-green-500 text-white'],
    ['blue', 'bg-blue-500 text-white'],
    ['yellow', 'bg-orange-500 text-white'],
    ['red', 'bg-red-500 text-white'],
  ]);

  function getColor(color: string): string {
    return colors.get(color) as string;
  }

  return (
    <div className="p-4 rounded shadow flex items-center bg-white border border-gray-300 min-w-[12rem]">
      <div className="flex items-center gap-4 w-full">
        <span
          className={`font-bold ${getColor(color)} rounded-full flex items-center justify-center 
          w-12 h-12 md:w-16 md:h-16 text-lg md:text-xl aspect-square`}
        >
          {count}
        </span>
        <p className="text-sm md:text-base lg:text-lg font-semibold text-gray-800 break-words text-center leading-tight">
          {label}
        </p>
      </div>
    </div>
  );
};

interface DialToConnectChartProps {
  ratio: number;
}
const DialToConnectChart: React.FC<DialToConnectChartProps> = ({ ratio }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  const data = {
    labels: ['Connected', 'Missed'],
    datasets: [
      {
        data: [ratio, 100 - ratio],
        backgroundColor: ['#10B981', '#E5E7EB'],
        hoverBackgroundColor: ['#059669', '#D1D5DB'],
      },
    ],
  };

  const options = {
    cutout: '60%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    const canvas = chartRef.current;

    if (canvas !== null) {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new Chart(canvas, {
        type: 'doughnut',
        data,
        options,
      });
    }
    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [data, options]);

  return <Doughnut data={data} options={options} />;
};

export default SummaryView;

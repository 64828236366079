import { SalesforceRepository } from '@/src-v2/Data/Repository/sync/salesforce/SalesforceRepository';
import { type SalesforceUseCase as SalesforceUseCaseType } from './salesforce.usecase';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type Account } from '@/src/pages/Settings/Shared/Entities/Account';

export function SalesforceUseCase(): SalesforceUseCaseType {
  const { getSalesforceAccounts, salesforceAuth, deleteAccount } = SalesforceRepository();

  return {
    async getSalesforceAccounts(organizationId: string, userId: string): Promise<ApiResponse<Account[]>> {
      const { data, error, success, status } = await getSalesforceAccounts(userId, organizationId);
      return { data, error, success, status };
    },
    async salesforceAuth(organizationId: string, userId: string): Promise<ApiResponse<string>> {
      const { data, error, success, status } = await salesforceAuth(organizationId, userId);
      return { data, error, success, status };
    },
    async deleteAccount(organizationId: string, userId: string, accountId: string): Promise<ApiResponse<boolean>> {
      const { data, error, success, status } = await deleteAccount(organizationId, userId, accountId);

      if (data === null || error !== null) {
        return { error, success };
      }

      return { data, success: true, status };
    },
  };
}

import DashboardDetailsUseCase from '@usecase/dashboard/DashboardDetailsUseCase';
import { useNavigate } from 'react-router-dom';
import {
  type PaginatedDashboardDetailResponse,
  type DashboardSessionDetail,
} from '@datasource/contacts-nest/dashboard/entities/dashboardSessionDetail';
import { type Dispatch, type SetStateAction, useState } from 'react';
import useAuthApi from '@/src/api/Auth/AuthAPI';
import { type ContactInfo, ContactSource } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import { toast } from '@components/ui/use-toast';
import { type RecordingsWithLink } from '@datasource/contacts-nest/dashboard/getSessionRecordings';
import { FilterUserType } from '@domain/enums/Users/users-filter';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { PrivateRoutes } from '@/src/constants/routesConnect';

interface DashboardDetailsViewModelProps {
  callSessionData: DashboardSessionDetail;
  getContactInformation: (contactId: string) => void;
  getDetailsInfo: (callSessionId: string, page: number) => void;
  sdrAudio: string;
  agentAudio: string;
  setSdrAudio: Dispatch<SetStateAction<string>>;
  setAgentAudio: Dispatch<SetStateAction<string>>;
  sdrAudioDuration: string;
  agentAudioDuration: string;
  openedModal: boolean;
  setOpenedRecordingModal: Dispatch<SetStateAction<boolean>>;
  contactInformation: Omit<ContactInfo, 'id' | 'contactsListId' | 'flagged'>;
  loading: boolean;
  checkUser: () => Promise<string>;
  getRecordings: (callId: string) => Promise<void>;
  paginationData: { pageNumber: number; pageSize: number; totalPages: number; totalCount: number };
}

export function DashboardDetailsViewModel(): DashboardDetailsViewModelProps {
  const [callSessionData, setCallSessionData] = useState<DashboardSessionDetail>({
    session: { organizationId: '', organizationName: '', sdrId: '', sdrName: '', callSessionId: '' },
    calls: [],
  });

  const [paginationData, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
  }>({ pageNumber: 1, pageSize: 9, totalPages: 0, totalCount: 0 });

  const [contactInformation, setContactInfo] = useState<Omit<ContactInfo, 'id' | 'contactsListId' | 'flagged'>>({
    accountName: '',
    crmId: '',
    email: '',
    jobTitle: '',
    name: '',
    notes: '',
    notesAgent: '',
    notesSDR: '',
    phone: '',
    type: ContactSource.CSV,
    meta: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [sdrAudio, setSdrAudio] = useState('');
  const [agentAudio, setAgentAudio] = useState('');
  const [sdrAudioDuration, setSdrDuration] = useState('');
  const [agentAudioDuration, setAgentDuration] = useState('');
  const [openedModal, setOpenedRecordingModal] = useState(false);
  const navigate = useNavigate();

  const { getDashboardDetails, getContactById, playSessionRecordings } = DashboardDetailsUseCase();
  const { getMe } = useAuthApi(); // TODO:  This is in V1 needs to be migrated

  function getContactInformation(contactId: string): void {
    void getContactById(contactId).then((contactResponse) => {
      setLoading(true);
      if (contactResponse.data !== undefined) {
        setContactInfo(contactResponse.data);
        setLoading(false);
        return contactResponse.data;
      } else {
        toast({
          variant: 'destructive',
          description: 'Could not show Contact Details. Check if the contacts still existing in its list.',
          title: 'Error',
        });
      }
    });
  }

  async function checkUser(): Promise<string> {
    const requester = await getMe();
    if (requester.data !== undefined) {
      const {
        data: { external_id: externalId },
      } = requester.data;

      return externalId;
    } else {
      return '';
    }
  }

  function getDetailsInfo(callSessionId: string, page: number): void {
    void checkUser().then((externalId: string) => {
      setLoading(true);
      void getDashboardDetails(callSessionId, externalId, page, paginationData.pageSize).then(
        (response: ApiResponse<PaginatedDashboardDetailResponse>) => {
          const data = response.data;
          if (data !== undefined) {
            setCallSessionData(data.data);
            setPagination({
              pageNumber: data.pageNumber,
              pageSize: paginationData.pageSize,
              totalPages: data.totalPages,
              totalCount: data.totalCount,
            });
            setLoading(false);
          }

          if (response.data === undefined) {
            toast({
              variant: 'destructive',
              description: 'Could not show Session Details. Please, check if the session contains completed Calls.',
              title: 'Error',
            });

            const lastPage = sessionStorage.getItem('lastDashboardPage') ?? '1';
            navigate(`/${PrivateRoutes.DASHBOARD_V2}?page=${lastPage}`, { replace: true });
          }
        },
      );
    });
  }

  async function retrieveRecording(callId: string): Promise<ApiResponse<RecordingsWithLink>> {
    const externalId = await checkUser();
    return await playSessionRecordings(callId, externalId);
  }

  async function getRecordings(callId: string): Promise<void> {
    setOpenedRecordingModal(true);
    await retrieveRecording(callId).then((recordings) => {
      if (recordings.data !== undefined) {
        const notNullRecordingData = [recordings.data.sdrCallRecording, recordings.data.agentCallRecording];

        notNullRecordingData.forEach((recording) => {
          if (recording?.label === FilterUserType.SDR.toUpperCase()) {
            setSdrDuration(recording?.duration);
            const { recordingUrl } = recording;
            setSdrAudio(recordingUrl);
          }

          if (recording?.label === FilterUserType.AGENT.toUpperCase()) {
            setAgentDuration(recording?.duration);
            const { recordingUrl } = recording;
            setAgentAudio(recordingUrl);
          }
        });
      } else {
        toast({ variant: 'default', title: 'Recording not found', description: 'No recording found for this call.' });
        setSdrAudio('');
        setSdrDuration('');

        setAgentAudio('');
        setAgentDuration('');

        setOpenedRecordingModal(false);
      }
    });
  }

  return {
    callSessionData,
    contactInformation,
    loading,
    sdrAudio,
    agentAudio,
    sdrAudioDuration,
    agentAudioDuration,
    openedModal,
    setSdrAudio,
    setAgentAudio,
    setOpenedRecordingModal,
    getContactInformation,
    getDetailsInfo,
    checkUser,
    getRecordings,
    paginationData,
  };
}

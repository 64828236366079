import { IconDefinition, IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

export const customSalesforceLogo: IconDefinition = {
  icon: [
    430, 
    430,
    [], 
    "",
    "M417.9 162.9c-4.8-27.8-21.5-51.8-45.6-66.3-20.1-12.3-44.7-18.8-69.5-18.2-11.4 0.2-22.6 1.7-33.2 4.3-20.1-26.6-50.7-43.9-85.4-43.9-59.8 0-108.4 48.5-108.4 108.4 0 3.3 0.1 6.5 0.4 9.7-16.6-2.3-33.5-0.3-49.1 5.7-22.1 8.5-40.1 23.6-52.3 43.1-11.8 18.9-17.9 40.5-17.4 62.5 0.3 13.2 3.4 26.2 9.1 38.1-13.3 11.6-24.3 25.5-31.7 41.2-11.3 23.8-13.7 50.8-6.5 75.7 7.5 25.8 23.9 47.7 46.1 63.3 22.2 15.6 49.1 23.3 76.2 22.1 17.6-0.8 34.8-5.4 50.2-13.5 14.1 7.9 29.8 12 45.7 12 31.3 0 60.4-14.6 79.8-39.6 24.5 15.4 54.4 19.8 82.6 12.1 27.4-7.4 50.6-25.2 65.2-50.3 14.6-25.1 18.5-55.3 10.8-83.4-5.4-21-16.5-40.2-31.9-55.1 15.4-14.7 26.7-33.9 31.7-54.9 6.2-25.6 3.2-52.7-8.7-76.3z",
  ],
  iconName: "salesforce-logo" as IconName,
  prefix: "custom" as IconPrefix,
};

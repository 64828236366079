import React, { useEffect, useState, useContext, ReactElement } from "react";
import Layout from "../../components/shared/Layout";
import { GenericTableHeader } from "../../components/shared/GenericTable";
import NewCSV from "../../components/newCSV/NewCSV";
import axios from "axios";
import routes from "../../constants/routes";
import { jsonToCSV } from "react-papaparse";
import {useNavigate, useLocation, useOutletContext, useLoaderData} from "react-router-dom";
import {
  CallSession,
  LocationState,
} from "../../components/shared/CallSession";
import getSequencesStepsFromOutreachAsync from "../../api/Sync/getSequenceStepsFromOutreachAsync";
import { UITabbedContainer, Tab } from "../../core/ui/UITabbedContainer";
import UICard from "../../core/ui/UICard";
import GenericList from "../../components/shared/GenericList";
import { UIButton } from "../../core/ui/UIElements";
import { featuresStore } from "../../components/app/FeaturesProvider";
import { Sequence, SequenceStep } from "../../types/OutreachTypes";
import Loader from "../../core/ui/UILoader";
import styled from "styled-components";
import UITable from "../../core/ui/UITable";
import tabTitle from "../../utils/updateTitle";
import { bodyFourMativ, headersFourmativ } from "./FourmativData";
import getListsFromHubspotAsync from "../../api/Sync/getListsFromHubspotAsync";
import getHubspotAccountsAsync from "../../api/Sync/getHubspotAccountsAsync";
import getOutreachAccountsAsync from "../../api/Sync/getOutreachAccountsAsync";
import { HubspotContact, HubspotList } from "../../types/HubspotTypes";
import getHubspotContactsAsync from "../../api/Sync/getHubspotContactsAsync";
import { showUIToast, UI_TOAST_TYPES } from "../../core/ui/UIToast";
import deleteContactAsync from "../../api/Contacts/deleteContactAsync";
import SearchBar from "../../components/searchBar/searchBar";
import { StatusCodes } from "http-status-codes";
import useTimeAgo from "../../Presentation/Common/hooks/useTimeAgo.hook";
import { motion } from "framer-motion";
import ButtonOutlined from "../../Presentation/Common/Buttons/ButtonOutlined.button";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import animations from "../../core/ui/base/Animations";
import useCallSessionViewModel from "../../../src-v2/Presentation/Views/CallSession/callSession.viewmodel.ts";
import useContactsViewModel from "../../../src-v2/Presentation/Views/Contacts/contacts.viewmodel.ts";
import ContactsFilter from "../../../src-v2/Presentation/Views/Contacts/Components/ContactsFilter.tsx";
import { Input } from "../../../src-v2/Presentation/Common/Components/ui/input";
import { type CallSession as CallSessionEntity } from "../../../src-v2/Data/DataSource/contacts-nest/callSession/entities/callSession.entity.ts";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const window = globalThis as any;

const headers: GenericTableHeader[] = [
  { text: "Status", width: "1" },
  { text: "Name", width: "2" },
  { text: "Company", width: "2" },
  { text: "Position", width: "2" },
  { text: "Phone Number", width: "2" },
  { text: "Notes", width: "1.5" }
];

const handleRenderFunction = ({ errorMessages }) => {

  const hasErrors = Array.isArray(errorMessages) && errorMessages.length > 0;
  const messages = hasErrors ? errorMessages.map(msg => `${msg}`).join('\n') : 'No errors';

  return (
    <div className="text-center">
      <style>
        {`
          .table-container {
            width: 100%; /* Asegúrate de que ocupa el 100% del ancho */
          }

          .error-message {
            display: none; /* Ocultar el mensaje por defecto */
            position: absolute; /* Posicionar el mensaje de error */
            padding: 10px;
            border-radius: 4px; /* Bordes redondeados */
            top: 50%; /* Centrar verticalmente */
            left: 50%; /* Centrar horizontalmente */
            transform: translate(-50%, -50%); /* Ajustar la posición para centrar */
            z-index: 1000; /* Asegurarse de que esté por encima de otros elementos */
          }

          .error-message.error {
            background-color: #f8d7da; /* Rojo para errores */
            color: #721c24; /* Color del texto para errores */
            border: 1px solid #f5c6cb; /* Borde del mensaje de error */
          }

          .error-message.no-error {
            background-color: #cce5ff; /* Azul claro para "No errors" */
            color: #004085; /* Color del texto para "No errors" */
            border: 1px solid #b8daff; /* Borde del mensaje de "No errors" */
          }

          .table-container:hover .error-message {
            display: block; /* Mostrar el mensaje al pasar el mouse */
          }

          .warning-icon {
            color: red; /* Cambiar el color del ícono de advertencia a rojo */
          }
        `}
      </style>
      <div className="table-container">
        <FA
          icon={hasErrors ? "warning" : "check"}
          className={`text-app-integrations-outreach h-5 w-5 icon-hover ${hasErrors ? 'warning-icon' : ''}`}
        />
        <div className={`error-message ${hasErrors ? 'error' : 'no-error'}`}>
          {messages}
        </div>
      </div>
    </div>
  );
};

const tableBodies = [
  {
    id: "type-id",
    customRender: true,
    renderFunc: handleRenderFunction
  }, 
  `name`, 
  `accountName`, 
  `jobTitle`, 
  `phone`, 
  `notes`
];

type SelectedItem = {
  id: string;
  title?: string;
  select?: boolean;
  type?: string;
  tendrilId: string;
  sequenceId: string;
};

interface ContactsListI {
  id: string;
  organizationId: string;
  title: string;
}

type OutreachProspectDto = {
  tendrilId: string;
  accountName: string;
  email: string;
  name: string;
  notes: string;
  phone: string;
  jobTitle: string;
  outreachProspectId: number;
  outreachSequenceId: string;
  outreachSequenceStep: string;
};

type OutreachProspectsResponse = {
  prospects: OutreachProspectDto[];
  createdAt: Date;
};

interface ContactInterface {
  accountName: string;
  contactListId: string;
  email: string;
  id: string;
  jobTitle: string;
  name: string;
  phone: string;
  crmId: string;
  notes: string;
  notesAgent: string;
  notesSDR: string;
  errorMessages: string[];
}

type ContactList = {
  id: string;
  contactListId: string;
  completed: boolean;
};

const TextContainer = styled.div`
  display: grid;
  place-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1rem;
  font-weight: 600;
  margin: auto;
`;

const StyledSpan = styled.span`
  margin-bottom: 1rem;
`;

const ContactsCounter = styled.div`
  width: 100%;
  height: 20px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: right;
`;

const Contacts = (): JSX.Element => {
  const {userSession, handleRoutesLogout, intervals, setIntervals} = useOutletContext() as any;
  const { outreach, fourMativ, hubspot, hubspotCustom, contactsFilter } =
    useContext(featuresStore).featuresState;
  const [callSession, setCallSession] = useState<CallSession>(
    {} as CallSession
  );
  const location = useLocation();
  const [isSession, setIsSession] = useState(true);
  const [orgId, setOrgId] = useState<string | undefined>("");
  const [contactsList, setContactsList] = useState<ContactsListI[]>([]);
  const [visibleNewCSV, setVisibleNewCSV] = useState(false);
  const [visibleTable, setVisibleTable] = useState(false);
  const [contacts, setContacts] = useState<ContactInterface[]>([]);
  const [optionSelected, setOptionSelected] = useState([] as any[]);
  const [contactListName, setContactListName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [newFile, setNewFile] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [btnText, setBtnText] = useState("Upload");
  const [showButton, setShowButton] = useState(false);
  const [tableHeaders, setTableHeaders] = useState(
    fourMativ ? headersFourmativ : headers
  );
  const [tableBody, setTableBody] = useState(
    fourMativ ? bodyFourMativ : tableBodies
  );
  const [loadingStatus, updateLoadingStatus] = useState<{
    sequences: boolean;
    contacts: boolean;
  }>({ sequences: true, contacts: false });
  const [label, setLabel] = useState<string | ReactElement>(
    "Select a sequence to show"
  );
  const [errorMessage, setError] = useState("");
  const [outreachSequenceSteps, dispatch] = useState([] as Sequence[]);
  const [hubspotSequences, setLists] = useState([] as HubspotList[]);
  const navigate = useNavigate();

  const setSequenceSteps = (sequences: any[]) => dispatch(sequences);

  const setHubspotLists = (sequences: any[]) => setLists(sequences);

  const contactsListLoader = useLoaderData() as any;

  const { timeAgo, updateTimeAgoDate } = useTimeAgo({
    timezone: userSession?.timezone,
  });
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [currentSelectedElement, setCurrentSelectedElement] =
    useState<SelectedItem>();
  const { titlesSession, formatAndSetData } = useCallSessionViewModel();
  const { viewModelContacts, sessionIds, setSessionIds, fetchContactsBySdrId } =  useContactsViewModel();
  if (window.location.search === "?setup=1") {
    tabTitle("Tendril Connect | Contacts - setup");
  } else {
    tabTitle("Tendril Connect | Contacts");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = location.state as LocationState;

    const setup = urlParams.get("setup");
    if (setup && setup === "1" && state) {
      setCallSession(state.callSession);
    } else {
    navigate( "/contacts");
      setIsSession(false);
    }
  }, [callSession, location.state]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setOrgId(userSession.organizationId);

        const contactsListUrl = `${routes.get.contacts.contactListsNest}/${userSession.organizationId}`;

        const contactListId = await axios.get<ContactsListI[]>(contactsListUrl);

        setContactsList(contactListId.data);

        updateLoadingStatus((prevState) => ({
          ...prevState,
          sequences: false,
        }));
      } catch (error: unknown) {
        const statusCode = axios.isAxiosError(error)
          ? error.response?.status
          : StatusCodes.INTERNAL_SERVER_ERROR;

        switch (statusCode) {
          case StatusCodes.NOT_FOUND:
            showUIToast({
              type: UI_TOAST_TYPES.INFO,
              text: "Please create your first contacts list.",
            });
            break;
          default:
            showUIToast({
              type: "error",
              text: "Something went wrong, try again later.",
            });
            break;
        }

        setVisibleNewCSV(true);
        updateLoadingStatus((prevState) => ({
          ...prevState,
          contacts: false,
          sequences: false,
        }));
      }
    };

    isMounted && fetchData();
    return () => {
      isMounted = false;
    };
  }, [userSession.userId]);

  useEffect(() => {
    const getContacts = async () => {
    if (optionSelected.length) {
      const found = optionSelected.some((el) => {
        if (el !== undefined) {
          if (el.id === "newItem" && el.select === true) {
            return el;
          }
        }
      });
      if (found) {
        setVisibleTable(false);
        setVisibleNewCSV(true);
        setShowButton(true);
      } else {
        const selectionInfo = optionSelected
  .filter(item => item !== undefined && item.select === true)
  .reduce((result, item) => {
    result.ids.push(item.id);
    result.isFiltered = item.type === 'filteredContacts';
    return result;
  }, { ids: [], isFiltered: false });

        if(contactsFilter && selectionInfo.isFiltered ) {

          setVisibleNewCSV(false);

          setDisabled(!disabled);

          setShowButton(!showButton);

          setSessionIds(selectionInfo.ids);

          selectionInfo.ids.length && await fetchContactsBySdrId(selectionInfo.ids).then((contactsResponse:any)=>{

            updateContactsViewStates(contactsResponse);

          });

        }else{

        optionSelected.map(async (item) => {
          if (item !== undefined) {
            if (item.select === false) {
              setVisibleNewCSV(false);
              setVisibleTable(false);
              setDisabled(true);
              setShowButton(false);
            }

            if (item.select === true && item.id !== "newItem") {
              let contactsResponse: ContactInterface[] | OutreachProspectDto[] =
                [];

              if (item.tendrilId) {
                if (item.type === "OUTREACH") {
                  contactsResponse = await getAllOutreachContacts(item);
                } else if (item.type === "CUSTOM_HUBSPOT") {
                  contactsResponse = await getAllHubspotContacts(item, true);
                } else {
                  contactsResponse = await getAllHubspotContacts(item);
                }
              }
              else {
                contactsResponse = await getAllContacts(item);
              }
              updateContactsViewStates(contactsResponse);
            }
          }
        });
      }
      }
    }
  }

  getContacts();

  }, [optionSelected]);

  useEffect(() => {
    const validateInput = () => {
      optionSelected.forEach((item) => {
        if (item !== undefined) {
          if (item.id !== "newItem" && item.select === true) {
            setBtnText("Upload");
            setDisabled(false);
          }
          if (item.id === "newItem" && item.select === true) {
            setBtnText("Save");
            if (contactListName.length > 0 && contacts.length > 0) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }
        }
      });
    };
    validateInput();
  }, [contactListName, optionSelected, contacts]);

  const selectOutreachContactsCallback = async () => {
    const titles: string[] = [];
    const viewContactList: ContactInterface[] = [];
    const updatedCallSession = callSession;

    const selectedItem = optionSelected.find((option) => {
      return option.select;
    });

    if (selectedItem) {
      titles.push(selectedItem.title);
      let gotContacts: ContactInterface[] = [];
      gotContacts = await createOutreachList(selectedItem);
      updateLoadingStatus({ ...loadingStatus, contacts: false });
      const previewContacts = gotContacts.slice(0, 3);
      previewContacts.forEach((i) => {
        viewContactList.push(i);
      });
      const contactList = {
        id: selectedItem.id,
        contactListId: previewContacts[0].contactListId,
        completed: false,
      };
      updatedCallSession.contactList = [contactList];
      updatedCallSession.contactListName = titles as [string];
      updatedCallSession.totalContacts = gotContacts.length;
      updatedCallSession.previewContacts = viewContactList as [
        ContactInterface
      ];

      // checkAndRedirectCallSession(callSession, history);
      if (!callSession.contactList) {
        navigate("/contacts?setup=1", {
          state: { callSession: callSession },
        });
      } else if (!callSession.voiceDropsIds) {
        navigate("/voice?setup=1",{
          state: { callSession: callSession },
        });
      } else if (!callSession.speech) {
        navigate("/script?setup=1", {
          state: { callSession: callSession },
        });
      } else {
        navigate("/home?setup=1",{
          state: { callSession: callSession },
        });
      }

      showUIToast({ type: "info", text: "Contact list selected." });
    }
  };

  const selectHubspotContactsCallback = async (custom = false) => {
    const titles: string[] = [];
    const viewContactList: ContactInterface[] = [];
    const updatedCallSession = callSession;

    const selectedItem = optionSelected.find((option) => {
      return option.select;
    });

    if (selectedItem) {
      titles.push(selectedItem.title);
      let hubspotContacts: ContactInterface[] = [];
      hubspotContacts = await createHubspotList(selectedItem, custom);
      updateLoadingStatus({ ...loadingStatus, contacts: false });
      const previewContacts = hubspotContacts.slice(0, 3);
      previewContacts.forEach((i) => {
        viewContactList.push(i);
      });
      const contactList = {
        id: selectedItem.id,
        contactListId: previewContacts[0].contactListId,
        completed: false,
      };
      updatedCallSession.contactList = [contactList];
      updatedCallSession.contactListName = titles as [string];
      updatedCallSession.totalContacts = hubspotContacts.length;
      updatedCallSession.previewContacts = viewContactList as [
        ContactInterface
      ];

      // checkAndRedirectCallSession(callSession, history);
      if (!callSession.contactList) {
        navigate("/contacts?setup=1", {
          state: { callSession: callSession },
        });
      } else if (!callSession.voiceDropsIds) {
        navigate("/voice?setup=1",{
          state: { callSession: callSession },
        });
      } else if (!callSession.speech) {
        navigate("/script?setup=1", {
          state: { callSession: callSession },
        });
      } else {
        navigate("/home?setup=1",{
          state: { callSession: callSession },
        });
      }

      showUIToast({ type: "info", text: "Contact list selected." });
    }
  };

  const selectContactsCallback = () => {
    const found = optionSelected.some((el) => {
      if (el !== undefined) {
        if (el.id === "newItem" && el.select === true) {
          return el;
        }
      }
    });

    const aux: ContactList[] = [];
    const titles: string[] = [];
    let totalContacts = 0;
    const viewContactList: ContactInterface[] = [];

    if (found) {
      uploadFiles(contactListName);
    } else {
      optionSelected.map(async (item) => {
        if (item !== undefined) {
          if (item.id !== "newItem" && item.select === true) {
            aux.push(item);
            titles.push(item.title);
            let gotContacts: ContactInterface[] = [];
            gotContacts = await getAllContacts(item);
            updateLoadingStatus({ ...loadingStatus, contacts: false });
            totalContacts += gotContacts.length;
            const previewContacts = gotContacts.slice(0, 3);
            previewContacts.forEach((i) => {
              viewContactList.push(i);
            });
            const callSession1 = callSession;
            callSession1.contactList = aux as [ContactList];
            callSession1.contactList.forEach((value) => {
              value.contactListId = gotContacts[0].contactListId;
              value.completed = false;
            });
            callSession1.contactListName = titles as [string];
            callSession1.totalContacts = totalContacts;
            callSession1.previewContacts = viewContactList as [
              ContactInterface
            ];

            // checkAndRedirectCallSession(callSession, history);

            if (!callSession.contactList) {
              navigate("/contacts?setup=1", {
                state: { callSession: callSession },
              });
            } else if (!callSession.voiceDropsIds) {
              navigate("/voice?setup=1",{
                state: { callSession: callSession },
              });
            } else if (!callSession.speech) {
              navigate("/script?setup=1", {
                state: { callSession: callSession },
              });
            } else {
              navigate("/home?setup=1",{
                state: { callSession: callSession },
              });
            }

            showUIToast({ type: "info", text: "Contact list selected." });
          }
        }
      });
    }
  };

  const getAllContacts = async (arr) => {
    try {
      updateLoadingStatus((prevState) => ({
        ...prevState,
        contacts: true,
      }));
      const contactsUrl = `${routes.get.contacts.contactsNest}/${arr.id}`;
      const contactsResponse = await axios.get<ContactInterface[]>(contactsUrl);
      return contactsResponse.data;
    } catch (error) {
      handleEmptyList("There are no contacts for selected contact list");
      throw error;
    }
  };

  const createOutreachList = async (selectedItem) => {
    try {
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const contactsUrl = `${routes.post.contact.contactsNest}/outreach`;

      const data = {
        organizationId: userSession.organizationId,
        title: selectedItem.title,
        outreachSequenceId: selectedItem.sequenceId,
        outreachSequenceStep: Number(selectedItem.id),
        nonce: selectedItem.tendrilId,
      };

      const contactsResponse = await axios.post<ContactInterface[]>(
        contactsUrl,
        data
      );

      return contactsResponse.data;
    } catch (error) {
      handleEmptyList("There are no contacts for selected contact list");
      throw error;
    }
  };

  const createHubspotList = async (selectedItem, custom) => {
    try {
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const contactsUrl = custom
        ? `${routes.post.contact.contactsNest}/hubspotCustom`
        : `${routes.post.contact.contactsNest}/hubspot`;

      const data = {
        organizationId: userSession.organizationId,
        title: selectedItem.title,
        listId: Number(selectedItem.id),
        nonce: selectedItem.tendrilId,
      };

      const contactsResponse = await axios.post<ContactInterface[]>(
        contactsUrl,
        data
      );

      return contactsResponse.data;
    } catch (error) {
      handleEmptyList("There are no contacts for selected contact list");
      throw error;
    }
  };

  const getAllOutreachContacts = async (
    { id, tendrilId, sequenceId }: SelectedItem,
    isRefresh = false
  ): Promise<OutreachProspectDto[]> => {
    try {
      setOutreachDisabledButton(true);
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const nonce = tendrilId;
      const sequenceStepId = id;
      const params = isRefresh ? `?update=${isRefresh}` : "";
      const contactsUrl = `${routes.get.outreach.getProspects}/${nonce}/${sequenceId}/${sequenceStepId}${params}`;
      const contactsResponse = await axios.get<OutreachProspectsResponse>(
        contactsUrl
      );

      setOutreachDisabledButton(false);
      updateTimeAgoDate(contactsResponse.data.createdAt);
      updateLoadingStatus({ ...loadingStatus, contacts: false });
      return contactsResponse.data.prospects;
    } catch (error) {
      console.log(error);
      handleEmptyList("There are no contacts for selected sequence step");
      throw error;
    }
  };

  const prepareHubspotContactsForList = (contacts: HubspotContact[]) => {
    return contacts.map((contact) => {
      const { vid, properties, notes } = contact;
      const contactListId = contact["portal-id"] ?? "";
      const name = `${properties.firstname?.value || ""} ${
        properties.lastname?.value || ""
      }`;
      const phone = properties.mobilephone?.value && properties.mobilephone.value !== ""
      ? properties.mobilephone.value
      : properties.phone?.value
      ? properties.phone.value
      : "";
      return {
        accountName: properties.company?.value || "",
        contactListId,
        email: properties.email?.value || "",
        id: vid,
        jobTitle: properties.jobtitle?.value || "",
        name,
        phone: phone,
        crmId: "",
        notes: notes ? notes.join("\n").replace(/(<([^>]+)>)/gi, "") : "",
        notesAgent: "",
        notesSDR: "",
        errorMessages: []
      };
    });
  };

  const getAllHubspotContacts = async ({ id, tendrilId }, custom = false) => {
    setHubspotDisabledButton(true);
    try {
      updateLoadingStatus({ ...loadingStatus, contacts: true });
      const { contacts, error } = await getHubspotContactsAsync(
        tendrilId,
        id,
        custom
      );

      if (contacts && contacts.length > 0) {
        let contactsResponse: ContactInterface[] = [];
        contactsResponse = prepareHubspotContactsForList(contacts);
        setHubspotDisabledButton(false);
        return contactsResponse;
      } else {
        handleEmptyList("There are no contacts for selected list");
        throw error;
      }
    } catch (error) {
      console.log(error);
      handleEmptyList("There are no contacts for selected list");
      throw error;
    }
  };

  const handleEmptyList = (label: string) => {
    updateLoadingStatus({ ...loadingStatus, contacts: false });
    setLabel(label);
    setVisibleTable(false);
    setVisibleNewCSV(false);
    showUIToast({
      type: "warning",
      text: label,
    });
  };

  const handleNoAccounts = () => {
    return (
      <>
        <StyledSpan>There are no accounts linked to this profile.</StyledSpan>
        <StyledSpan>To link an account go to settings.</StyledSpan>
        <UIButton onClick={goToSettings}>Settings</UIButton>
      </>
    );
  };

  const goToSettings = () => {
    const navigate = useNavigate();
    navigate("/settings");
  };

  const uploadFiles = async (contactListName, isFilteredContacts = false) => {
    try {
      let contactListPayload = {
        organizationId: orgId,
        title: contactListName,
        type: fourMativ ? "FOURMATIV" : "CSV",
        nonce: fourMativ ? "FOURMATIV" : "CSV",
      };

      const contactListResponse = await axios.post(
        routes.post.contact.contactsListNest,
        contactListPayload
      );

      if (
        contactListResponse.status === 200 ||
        contactListResponse.status === 201
      ) {
        showUIToast({ type: "success", text: "A new list was created." });
        const url = `${routes.post.contact.contactsNest}/${contactListResponse.data.id}`;

        let flagResponse:boolean = false;

        if (contactsFilter && isFilteredContacts) {
          let contactsWithOutId = contacts.map(contact => {
            const { id, ...contactWithOutId } = contact;
            contactWithOutId.contactListId = contactListResponse.data.id;
            return contactWithOutId;
          });

          while (contactsWithOutId.length > 0) {
            const chunk = contactsWithOutId.splice(0, 50);

            const chunkResponse = await axios.post(url, chunk, {
              params: {
                orgId: contactListResponse.data.organizationId
              }
            });

            flagResponse = chunkResponse.status === 200 || chunkResponse.status === 201 ? true: false;
          }
        } else {
        const blob = new Blob(["\ufeff", newFile]);

        const formData = new FormData();

        formData.append("contactListId", contactListResponse.data.id);

        formData.append("file", blob);

        let response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            orgId: contactListResponse.data.organizationId
          }
        });
        flagResponse = response.status === 200 || response.status === 201 ? true: false;
        }

        if (flagResponse) {
          showUIToast({
            type: "success",
            text: `${contacts.length} new contacts were added.`,
          });

          const contactListFiltered = contactsList.filter(
            (item) => item.id !== "newItem"
          )

          setContactsList([contactListResponse.data, ...contactListFiltered]);
        }
      }
    } catch (error) {
      showUIToast({
        type: "error",
        text: "Something went wrong, try again later.",
      });
    }
  };

  const handleDeleteContactList = async (element) => {
    setOptionSelected([]);
    try {
      const { id } = element;

      if (id) {
        const deleteContactListUrl = `${routes.delete.contacts.deleteContactListNest}/${id}`;
        const deleteContactListReq = await axios.delete(deleteContactListUrl);

        if (deleteContactListReq.status === 200) {
          showUIToast({ type: "info", text: "Contact list deleted." });
          const contactListUpdate = contactsList.filter((item) => {
            if (item.id !== element.id && item.id !== "newItem") {
              return item;
            }
          });
          setContactsList(contactListUpdate);
        }
      }
    } catch (error) {
      showUIToast({
        type: "error",
        text: "Something went wrong, try again later.",
      });
    }
  };

  const handleDeleteAContact = async (elem) => {
    const { id } = elem;
    if (id?.length < 24) {
      setContacts(contacts.filter((item) => Number(item.id) !== Number(id)));
      handleOnDrop(contacts.filter((item) => Number(item.id) !== Number(id)));
    } else {
      if (!id) return;
      const { success, error } = await deleteContactAsync(id);
      if (success) {
        showUIToast({ type: "info", text: "Contact deleted." });
        const contactsUpdate = contacts.filter((item) => item.id !== id);
        setContacts(contactsUpdate);
        return;
      }
      error &&
        showUIToast({
          type: "error",
          text: error.message || "Something went wrong.",
        });
    }
  };

  const handleSelectAnOption = (data: []) => {
   if (data.length && data.length < 0) setVisibleTable(true);
    setOptionSelected(data);
    setOutreachDisabledButton(true);
    setHubspotDisabledButton(true);
  };

  const addNewListAndUsers = async () => {
    const selectedItem = optionSelected.find((elem) => elem.select === true);

    const isFilteredContacts = selectedItem.type === 'filteredContacts';

    setNewFile("");

    setContactListName("");

    await uploadFiles( contactListName, isFilteredContacts);
  };

  const prepareSequenceStepsForList = (sequences: SequenceStep[]) => {
    return sequences.map((sequence) => {
      const { id, attributes, tendrilId, sequenceId } = sequence;
      return {
        id: id.toString(),
        title: sequence.sequenceName
          ? `${sequence.sequenceName} - ${attributes.displayName}`
          : attributes.displayName,
        tendrilId,
        sequenceId,
        type: "OUTREACH",
      };
    });
  };

  const prepareHubspotListsForList = (
    sequences: HubspotList[],
    custom = false
  ) => {
    return sequences.map((sequence) => {
      const { listId, name, nonce } = sequence;
      return {
        id: listId,
        title: name,
        tendrilId: nonce,
        type: custom ? "CUSTOM_HUBSPOT" : "HUBSPOT",
      };
    });
  };

  const handleChangeInput = (e) => {
    setContactListName(e.target.value);
  };

  const handleOnDrop = (data) => {
    const allData = data;
    const getDataFile = (data) => {
      if (data.length > 0) {
        const filterData = data.map((element) => {
          if (element?.name && element?.phone) {
            if (fourMativ) {
              return {
                name: element?.name,
                phone: element?.phone,
                jobTitle: element?.jobTitle,
                email: element?.email,
                accountName: element?.accountName,
                notes: element?.notes,
                notesAgent: element?.notesAgent,
                notesSDR: "",
                crmId: element?.crmId,
                ssid: element?.ssid,
                firstName: element?.firstName,
                lastName: element?.lastName,
                dateOfBirth: element?.dateOfBirth,
                gender: element?.gender,
                grade: element?.grade,
                returningHBA: element?.returningSchool,
                leavingReason: element?.leavingReason,
                firstParentGuardianName: element?.firstParentGuardianName,
                firstParentGuardianLastName:
                  element?.firstParentGuardianLastName,
                firstParentGuardianEmail: element?.firstParentGuardianEmail,
                firstParentGuardianPhone: element?.firstParentGuardianPhone,
                firstParentGuardianRelationChild:
                  element?.firstParentGuardianRelationChild,
                secondParentGuardianName: element?.secondParentGuardianName,
                secondParentGuardianLastName:
                  element?.secondParentGuardianLastName,
                secondParentGuardianEmail: element?.secondParentGuardianEmail,
                secondParentGuardianPhone: element?.secondParentGuardianPhone,
                secondParentGuardianRelationChild:
                  element?.secondParentGuardianRelationChild,
                phoneTwo: element?.phoneTwo,
                contactNameTwo: element?.contactNameTwo,
                homeAddress: element?.homeAddress,
                homeLineTwo: element?.homeLineTwo,
                homeCity: element?.homeCity,
                homeZip: element?.homeZip,
                transportationStatus: element?.transportationStatus,
                amAddress: element?.amAddress,
                lineTwo: element?.lineTwo,
                amCity: element?.amCity,
                amZip: element?.amZip,
                pmAddress: element?.pmAddress,
                pmLineTwo: element?.pmLineTwo,
                pmCity: element?.pmCity,
                pmZip: element?.pmZip,
                daysOfWeek: element?.daysOfWeek,
                incomingSibling: element?.incomingSibling,
                additionalNotes: element?.additionalNotes,
              };
            } else {
              return {
                name: element?.name,
                phone: element?.phone,
                jobTitle: element?.jobTitle,
                email: element?.email,
                accountName: element?.accountName,
                notes: element?.notes,
                notesAgent: element?.notesAgent,
                notesSDR: "",
                crmId: element?.crmId,
              };
            }
          } else {
            return "";
          }
        });

        const contactsWithoutError = filterData.filter(
          (item) => item.phone !== "#ERROR!"
        );

        const validationFilterData = filterData.find((item) => item !== "");

        if (validationFilterData !== undefined) {
          const dataFile = jsonToCSV(contactsWithoutError, {
            delimiter: ",",
            header: true,
            skipEmptyLines: true,
          });
          setNewFile(dataFile);
          const totalContactsRemoved =
            allData.length - contactsWithoutError.length;

          if (totalContactsRemoved > 0) {
            showUIToast({
              type: "warning",
              text: `${totalContactsRemoved} contacts were removed, due to an incorrect or invalid phone number.`,
            });
          }
        }
      } else {
        setVisibleTable(false);
        setVisibleNewCSV(true);
      }
    };

    if (data[0]?.name) {
      const contactsWithoutError = data.filter((item) => {
        if (item.phone !== "#ERROR!") {
          return item;
        }
      });
      getDataFile(contactsWithoutError);
      const dataToShow = contactsWithoutError.map((item, index) => {
        if (
          item.name !== undefined ||
          item.phone !== undefined ||
          item.jobTitle !== undefined ||
          item.accountName !== undefined
        ) {
          if (item.name === "" || item.phone === "") {
            return {
              ...item,
              id: index,
              error: true,
            };
          } else {
            return {
              ...item,
              id: index,
              error: false,
            };
          }
        } else {
          return undefined;
        }
      });

      if (dataToShow[0] === undefined) {
        setVisibleNewCSV(true);
        showUIToast({
          type: "warning",
          text: "CSV doesn't match the current template.",
        });
        setDisabled(false);
      } else {
        setContacts(dataToShow);
        setVisibleNewCSV(false);
        setVisibleTable(true);
        if (fourMativ) {
          setTableHeaders(headersFourmativ);
          setTableBody(bodyFourMativ);
        }
      }
    } else {
      getDataFile(data);
    }
  };

  const showTrashIcon = () => {
    const elem = optionSelected.find((elem) => elem.select === true);
    return elem && elem.id !== "newItem";
  };

  const handleOnError = () => {
    showUIToast({
      type: "error",
      text: "Something went wrong, try again later.",
    });
  };

  const handleOnRemoveFile = () => {
    showUIToast({ type: "info", text: "CSV file deleted." });
    setNewFile("");
  };

  const initialTab = "csvTab";
  const [currentTab, setCurrentTab] = useState(initialTab);

  const contactTabs: Tab[] = [
    {
      id: "csvTab",
      header: "CSV Contacts",
      content: (index) => (
        <>
          <SearchBar
            key={index}
            placeholder="Search by title..."
            onSearchResult={(searchResult) => {
              if (searchResult?.success && !!searchResult.data) {
                setContactsList(searchResult.data as ContactsListI[]);
              }
            }}
            fields={["title"]}
            urlEndpoint={`${routes.get.contacts.contactListNest}?organizationId=${userSession.organizationId}&`}
          />
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={contactsList}
            deleteItem={handleDeleteContactList}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
            newItemTitle="+ New List"
          />
          {showButton && (
            <UIButton
              data-qa-id="contacts-btn"
              disabled={visibleNewCSV}
              onClick={isSession ? selectContactsCallback : addNewListAndUsers}
            >
              {btnText}
            </UIButton>
          )}
        </>
      ),
      callback() {
        setCurrentTab("csvTab");
        setVisibleNewCSV(true);
        setLabel("Select a contact list to show");
      },
    },
  ];

  const [outreachDisabledButton, setOutreachDisabledButton] =
    useState<boolean>(true);

  if (outreach) {
    contactTabs.push({
      id: "outreachTab",
      header: "Outreach",
      content: (index) => (
        <>
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={outreachSequenceSteps}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
            setCurrentSelection={(current) =>
              setCurrentSelectedElement(current)
            }
          />
          {isSession && (
            <UIButton
              disabled={outreachDisabledButton}
              onClick={selectOutreachContactsCallback}
            >
              Upload
            </UIButton>
          )}
        </>
      ),
      async callback() {
        setVisibleTable(false);
        setVisibleNewCSV(false);
        setLabel("Select a sequence to show");
        updateLoadingStatus({ ...loadingStatus, sequences: true });
        const { accounts, error: accountsError } =
          await getOutreachAccountsAsync(
            userSession.userId,
            userSession.organizationId
          );
        if (accounts && accounts.length > 0) {
          const { sequenceSteps, error } =
            await getSequencesStepsFromOutreachAsync(
              userSession.userId,
              userSession.organizationId
            );
          if (sequenceSteps) {
            setSequenceSteps(prepareSequenceStepsForList(sequenceSteps));
            setCurrentTab("outreachTab");
          } else if (error) {
            handleEmptyList(
              "There are no sequences for your current accounts."
            );
            console.log(error);
          }
        } else if (accountsError) {
          setLabel(handleNoAccounts());
          console.log(accountsError);
        }
        updateLoadingStatus({ ...loadingStatus, sequences: false });
      },
    });
  }

  if(contactsFilter && !isSession){
    contactTabs.push(
      {
        id: "sessionsTab",
        header: "Sessions",
        content: (index) => (
          <>
            <SearchBar
            key={index}
            placeholder="Search by list title..."
            onSearchResult={(searchResult) => {
              if (searchResult?.success && !!searchResult.data) {
                formatAndSetData(searchResult.data as CallSessionEntity[]);
              }
            }}
            fields={["title"]}
            urlEndpoint={routes.get.callSession.getBySdrIdNest + `/${userSession.userId}/orgId/${orgId}`}
          />
            <GenericList
              radioBtn={isSession}
              height="377px"
              data={titlesSession}
              setVisible={setVisibleNewCSV}
              setOptionSelected={handleSelectAnOption}
              multipleSelection={true}
            />
            <UIButton
              data-qa-id="contacts-btn"
              disabled={(contactListName === "" || !visibleTable)}
              onClick={addNewListAndUsers}
            >
              Save
            </UIButton>
          </>
        ),
        callback() {
          setCurrentTab("sessionsTab");
          setVisibleNewCSV(false);
          setVisibleTable(false);
          setLabel("Select a contact list to show");
        },
      },
    )
  }

  const [hubspotDisabledButton, setHubspotDisabledButton] =
    useState<boolean>(true);

  if (hubspot) {
    contactTabs.push({
      id: "hubspotTab",
      header: "HubSpot",
      content: (index) => (
        <>
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={hubspotSequences}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
            setCurrentSelection={(current) =>
              setCurrentSelectedElement(current)
            }
          />
          {isSession && (
            <UIButton
              disabled={hubspotDisabledButton}
              onClick={() => selectHubspotContactsCallback()}
            >
              Upload
            </UIButton>
          )}
        </>
      ),
      async callback() {
        setVisibleTable(false);
        setVisibleNewCSV(false);
        setHubspotLists([]);
        setLabel("Select a sequence to show");
        updateLoadingStatus({ ...loadingStatus, sequences: true });
        const { accounts, error: accountsError } =
          await getHubspotAccountsAsync(
            userSession.userId,
            userSession.organizationId
          );
        if (accounts && accounts.length > 0) {
          const { lists, error } = await getListsFromHubspotAsync(
            userSession.userId,
            userSession.organizationId
          );
          if (lists) {
            setHubspotLists(prepareHubspotListsForList(lists));
            setCurrentTab("hubspotTab");
          } else if (error) {
            handleEmptyList("There are no lists for your current accounts.");
            console.log(error);
          }
        } else if (accountsError) {
          setLabel(handleNoAccounts());
          console.log(accountsError.message);
        }
        updateLoadingStatus({ ...loadingStatus, sequences: false });
      },
    });
  }

  if (hubspotCustom) {
    contactTabs.push({
      id: "hubspotCustomTab",
      header: "HubSpot Custom",
      content: (index) => (
        <>
          <GenericList
            radioBtn={isSession}
            height="377px"
            data={hubspotSequences}
            setVisible={setVisibleNewCSV}
            setOptionSelected={handleSelectAnOption}
            setCurrentSelection={(current) =>
              setCurrentSelectedElement(current)
            }
          />
          {isSession && (
            <UIButton
              disabled={hubspotDisabledButton}
              onClick={() => selectHubspotContactsCallback(true)}
            >
              Upload
            </UIButton>
          )}
        </>
      ),
      async callback() {
        setVisibleTable(false);
        setVisibleNewCSV(false);
        setHubspotLists([]);
        setLabel("Select a sequence to show");
        updateLoadingStatus({ ...loadingStatus, sequences: true });
        const { accounts, error: accountsError } =
          await getHubspotAccountsAsync(
            userSession.userId,
            userSession.organizationId,
            true
          );
        if (accounts && accounts.length > 0) {
          const { lists, error } = await getListsFromHubspotAsync(
            userSession.userId,
            userSession.organizationId,
            true
          );
          if (lists) {
            setHubspotLists(prepareHubspotListsForList(lists, true));
            setCurrentTab("hubspotCustomTab");
          } else if (error) {
            handleEmptyList("There are no lists for your current accounts.");
            console.log(error);
          }
        } else if (accountsError) {
          setLabel(handleNoAccounts());
          console.log(accountsError.message);
        }
        updateLoadingStatus({ ...loadingStatus, sequences: false });
      },
    });
  }

  const handleRefresh = async () => {
    if (currentSelectedElement === undefined) {
      showUIToast({ type: "warning", text: "Please select a sequence step" });
      return;
    }

    switch (currentTab) {
      case "outreachTab":
        const outreachContacts = await getAllOutreachContacts(
          currentSelectedElement,
          true
        );
        updateContactsViewStates(outreachContacts);
        break;
    }
  };

  const updateContactsViewStates = (fetchedContacts: Array<any>): void => {
    
    if (!fetchedContacts || fetchedContacts.length === 0) {
      handleEmptyList("There are no contacts for selected list");
    }

    setContacts(fetchedContacts ?? []);
    setVisibleNewCSV(false);
    setVisibleTable(true);
    setShowButton(isSession);
    updateLoadingStatus({ ...loadingStatus, contacts: false });
  };

  useEffect(() => {
    switch (currentTab) {
      case "outreachTab":
        setShowRefreshButton(true);
        break;
      default:
        setShowRefreshButton(false);
        break;
    }
  }, [currentTab]);

  return (
    <Layout
      sidebar
      handleLogout={handleRoutesLogout}
      user={userSession.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UITabbedContainer
        title=""
        maxWidth="530px"
        tabs={contactTabs}
        loading={loadingStatus.sequences}
        loadingMessage={"Loading content..."}
        isSettings={false}
      />
      <UICard
        title={`${
          visibleNewCSV ? "Upload a new CSV" : "Contacts from selected List"
        }`}
        showTitle={false}
        width={`${visibleNewCSV ? "375px" : "720px"}`}
        minHeight="460px"
      >
        {visibleTable && (
          <motion.div
            {...animations.fadeInFromTop}
          >
            <div className="flex-col items-end">
            <div className="flex min-h-full gap-2 justify-between place-items-center mb-2">
            <div className="relative flex items-center">
   {(contactsFilter && currentTab === 'sessionsTab') &&
   <ContactsFilter
    selectedSession={sessionIds}
    onChangeFilters={fetchContactsBySdrId}
    updateContactsViewStates={updateContactsViewStates}
    className="absolute right-0 mt-4" />

    }
  </div>
            <div className="flex gap-2 place-items-center">
              {showRefreshButton === true && timeAgo && (
                <>
                  <p>{timeAgo}</p>
                  <ButtonOutlined
                    icon={faArrowRotateRight}
                    onClick={handleRefresh}
                    loading={loadingStatus.contacts}
                  />
                </>
              )}
            </div>


            <div className="flex items-center">


    <p style={{ marginRight: '20px' }}>Contacts: {contacts.length || 0}</p>

    <p>Contacts with errors: {contacts.filter(contact => Array.isArray(contact.errorMessages) && contact.errorMessages.length > 0).length}</p>

</div>
</div>
{ (contactsFilter && currentTab === 'sessionsTab') &&
<Input
  data-qa-id={`input-contact-list-sessions-name`}
  type="text"
  placeholder="List name"
  onChange={handleChangeInput}
  className="text-base placeholder-gray-300 sm:gray-300 md:placeholder-gray-300 lg:placeholder-gray-300 xl:placeholder-gray-300"
/>

}
</div>
          </motion.div>
        )}

        {loadingStatus.contacts ? (
          <Loader message={"Loading contacts, please wait..."} />
        ) : (
          <>
            {visibleNewCSV && (
              <NewCSV
                width="100%"
                height="auto"
                handleOnDrop={handleOnDrop}
                handleOnError={handleOnError}
                handleOnRemoveFile={handleOnRemoveFile}
                setContactListName={setContactListName}
                fourMativ={fourMativ}
              />
            )}
            {visibleTable && (
              <UITable
                maxHeight="377px"
                headers={
                  currentTab === "csvTab"
                    ? [...tableHeaders, { text: "", width: "0.50" }]
                    : tableHeaders
                }
                tableBodies={
                  currentTab === "csvTab"
                    ? !showTrashIcon()
                      ? tableBody
                      : [...tableBody, { icon: "trash" }]
                    : tableBody
                }
                data={contacts}
                selectionHandler={handleDeleteAContact}
                setSelectedRow={setSelectedRow}
                visible={false}
              />
            )}
            {!visibleTable && !visibleNewCSV && (
              <TextContainer>{label}</TextContainer>
            )}
          </>
        )}
      </UICard>
    </Layout>
  );
};

export default Contacts;

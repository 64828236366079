import React from "react";
import { Field, FieldRenderProps, FieldMetaState } from "react-final-form";
import styled from "styled-components";
import { Box, Flex, Text, Button } from "../../core/ui";

export const StyledForm = styled.form`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
`;

const FieldContainer = styled(Box)`
  width: 220px;
  margin: 12px 0;

  input[type="text"],
  input[type="password"] {
    width: 100%;
    height: 36px;
    margin-top: 8px;
    padding: 0 10px;
    font-size: 14px;
    border: solid 1px ${({ theme }) => theme.colors.lightGray};
  }

  input[type="password"] {
    font-size: 16px;
  }

  select {
    width: 220px;
    height: 36px;
    margin-top: 8px;
    font-size: 14px;
    border: solid 1px ${({ theme }) => theme.colors.lightGray};
  }
`;

const LabelWrapper = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    cursor: pointer;
  }
`;

const StyledText = styled(Text)`
  margin-left: 6px;
  font-size: 14px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const ErrorText = styled(Text)`
  margin-left: 8px;
  font-size: 12px;
  color: #f85149;
  text-align: left;
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  &:disabled {
    opacity: 0.4;
  }
`;

interface LabelProps {
  label: string | React.ReactNode;
  meta?: FieldMetaState<any>; 
}

const Label: React.FC<LabelProps> = ({ label, meta }) => (
  <LabelWrapper>
    {typeof label === "object" && label}
    {typeof label === "string" && <label>{label}</label>}
    {meta && meta.error && meta.touched && <ErrorText>{meta.error}</ErrorText>}
  </LabelWrapper>
);

interface InputFieldProps extends FieldRenderProps<any, HTMLElement> {
  label: string;
}

export const InputField: React.FC<InputFieldProps> = ({ input, meta, label, ...rest }) => {
  const qaId = typeof label === 'string'
    ? label.split(" ").join("_").toLowerCase()
    : "";

  return (
    <FieldContainer {...rest}>
      <Label label={label} meta={meta} />
      <input
        {...input}
        data-qa-id={`input_${qaId}`}
      />
    </FieldContainer>
  );
};

interface SelectFieldProps extends FieldRenderProps<any, HTMLElement> {
  label: string;
  options: Array<{ name: string; _id?: string; orgName?: string; id?: string }>;
  customOnChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  customRender?: (options: SelectFieldProps['options']) => React.ReactNode;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  input,
  meta,
  label,
  options,
  customOnChange,
  customRender,
}) => (
  <FieldContainer>
    <Label label={label} meta={meta} />
    <select
      {...input}
      data-qa-id={`selectInput_${label}`}
      onChange={(e) => {
        input.onChange(e);
        customOnChange && customOnChange(e);
      }}
    >
      <option value="" disabled>
        Select an option
      </option>
      {customRender
        ? customRender(options)
        : options.map(({ name, _id, orgName, id }) => (
            <option
              value={_id ? _id : id}
              key={_id ? _id : id}
              data-qa-id={`select_${name.split(" ").join("_")}`}
            >
              {orgName ? `${name} - ${orgName}` : name}
            </option>
          ))}
    </select>
  </FieldContainer>
);

interface CheckboxFieldProps extends FieldRenderProps<any, HTMLElement> {
  label: string;
  customOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  visible?: (isVisible: boolean) => void;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  input,
  label,
  customOnChange,
  visible,
}) => (
  <Flex>
    <input
      data-qa-id={`checkboxUserType_${label}`}
      {...input}
      onChange={(e) => {
        input.onChange(e);
        customOnChange && customOnChange(e);
        input.checked === true
          ? visible && visible(true)
          : visible && visible(false);
      }}
    />
    <StyledText>{label}</StyledText>
  </Flex>
);

interface FieldsGroupProps {
  groupName: string;
  label: string;
  children: React.ReactNode;
}

export const FieldsGroup: React.FC<FieldsGroupProps> = ({ groupName, label, children }) => {
  const labelWithErrors = (
    <>
      <label>{label}</label>
      <Error name={groupName} />
    </>
  );

  return (
    <FieldContainer as="fieldset">
      <Label label={labelWithErrors}></Label>
      {children}
    </FieldContainer>
  );
};

interface ErrorProps {
  name: string;
}

export const Error: React.FC<ErrorProps> = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <ErrorText>{error}</ErrorText> : null
    }
  />
);

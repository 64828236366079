import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {ILogger} from "@/src-v2/Shared/logger/logger.service";
import {toast} from "@components/ui/use-toast";
import {LogPolicies, LogLevels, requestTimeoutConfig } from '../utils/classes/requestTimeoutConfig';

const userSession = JSON.parse(window.localStorage.getItem("contextUser") || "{}");
const checkSpeedOnSlowerEndpoints = (requestUrl: string): {maxTimeAllowed: number, logLevel: LogPolicies} => {
    const respondedConfig = requestTimeoutConfig.find(config =>
        config.slug !== undefined && requestUrl.includes(config.slug as string)
    ) || requestTimeoutConfig[0]; // Default to the first config if no match is found

    const { maxTimeAllowed, logLevel } = respondedConfig;
    return { maxTimeAllowed, logLevel };
  }

const checkConnectionPerformance = (logger: ILogger)=>  {
    let startTime: number;
    let endTime: number;
    let requestUrl: string | undefined;
    let timeElapsed;



        axios.interceptors.request.use((request: InternalAxiosRequestConfig):any=> {
        startTime =  new Date().getTime();
        requestUrl = request.url;
        return request;
    });

    axios.interceptors.response.use((response: AxiosResponse<any>):any => {
        endTime = new Date().getTime();
        timeElapsed = endTime - startTime
        const {userId, email, isSdr, isAgent} = userSession;


        let endpointsConfig = checkSpeedOnSlowerEndpoints(requestUrl as string)
            const timeLimit = endpointsConfig.maxTimeAllowed;

                logger.log(`Request to endpoint: ${requestUrl}, took: ${timeElapsed} ms`, {
                    componentName: 'CheckConnectionPerformance',
                    additionalContext: response.statusText,
                });

                const notify = ()=> {
                    toast({
                        itemID: '1',
                        variant: 'destructive',
                        title: 'Slow Internet',
                        description: 'Your internet connection was detected slow. This might cause operation problems'
                    });
                }


        if ( timeElapsed !== undefined && timeElapsed > timeLimit ){
            switch (endpointsConfig.logLevel){
                case LogLevels.WarnAll: {
                    notify(); break;
                }
                case LogLevels.WarnSDR: {
                    if (isSdr){
                        notify();
                    } break;
                }
                case LogLevels.WarnAgent: {
                    if (isAgent){
                        notify();
                    } break;
                }
                default: break; // Only Log Policy
            }
            const sessionIsDefined = userSession === '{}' || userSession == undefined;

            logger.warn(
                sessionIsDefined
                ? `Warning: User session could not been detected. Slow internet detected at endpoint: ${requestUrl}, elapsed time: ${timeElapsed} ms`
                : `Warning: User [${userId}:${email}] has slow internet, at endpoint: ${requestUrl}, elapsed time: ${timeElapsed} ms`, {
                componentName: 'CheckConnectionPerformance',
                additionalContext: response.statusText,
            });
        }
        return response;
    });
}

export default checkConnectionPerformance;

import React, { useContext } from "react";
import Layout from "../../components/shared/Layout";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import UICard from "../../core/ui/UICard";

import styled from "styled-components";
import SummaryContainer from "./components/SummaryContainer";

import { UIIconButton } from "../../core/ui/UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { UserProviderContext } from "../../components/app/UserProvider";
import {PrivateRoutes} from "../../constants/routesConnect";

const BackContainer = styled.label`
  cursor: pointer;
`;

const Summary = (): JSX.Element => {
  const {handleRoutesLogout, intervals, setIntervals} = useOutletContext() as any;

  const { currentUser } = useContext(UserProviderContext);
  const navigate = useNavigate();

  let { callsessionid } = useParams();

  const handleDashboard = async () => {
    const lastPage = sessionStorage.getItem('lastDashboardPage') ?? '1';
    navigate(`/${PrivateRoutes.DASHBOARD_V2}?page=${lastPage}`, { replace: true });
  };

  return (
    <Layout
      sidebar
      handleLogout={handleRoutesLogout}
      user={currentUser.userName}
      intervals={intervals}
      setIntervals={setIntervals}
    >
      <UICard title={"Summary"} width={"100%"}>
        <BackContainer>
          <UIIconButton onClick={handleDashboard}>
            <FA icon={faArrowLeft} /> Back to Dashboard
          </UIIconButton>
        </BackContainer>

        <SummaryContainer key={callsessionid} callSessionId={callsessionid as string} />
      </UICard>
    </Layout>
  );
};

export default Summary;

import axios from 'axios';
import routes from '../../../../../src/constants/routes';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';
import { type AuthSalesforce } from './dto/auth-salesforce.dto';

const salesforceAuth = async (organizationId: string, userId: string): Promise<ApiResponse<string>> => {
  try {
    const url = `${routes.post.salesforce.oAuthLogin}/${organizationId}/${userId}`;
    const salesforceAccounts = await axios.post<AuthSalesforce>(url);
    return { data: salesforceAccounts.data.url, success: true, status: salesforceAccounts.status };
  } catch (e: unknown) {
    return {
      success: false,
      error: { message: 'Error while getting salesforce accounts.' },
    };
  }
};

export default salesforceAuth;

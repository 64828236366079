import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import axios from 'axios';

const deleteSalesforceAccountAsync = async (
  orgId: string,
  userId: string,
  accountId: string,
): Promise<ApiResponse<boolean>> => {
  try {
    await axios.delete(`${routes.delete.salesforce.deleteAccount}/${orgId}/${userId}/${accountId}`);

    return { success: true };
  } catch (error) {
    return { error: { message: 'Error while deleting account' } };
  }
};

export default deleteSalesforceAccountAsync;

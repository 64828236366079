import styled from "styled-components";
import {
  space,
  width,
  height,
  textAlign,
  display,
  maxWidth,
  borderRadius,
  fontSize,
  fontWeight,
  lineHeight,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  overflow,
  flex,
  SpaceProps,
  WidthProps,
  HeightProps,
  TextAlignProps,
  DisplayProps,
  MaxWidthProps,
  BorderRadiusProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  FlexWrapProps,
  FlexDirectionProps,
  JustifyContentProps,
  AlignItemsProps,
  OverflowProps,
  FlexProps as FlexShorthand,
} from "styled-system";

// We don't use the styled-system ColorProps because of compatibility issues
type ColorProps = {
  bg?: string;
  color?: string;
  theme: {
    colors: Record<string, string>;
  };
};

const color = (props: ColorProps) => {
  const { theme, bg, color } = props;
  return {
    ...(bg && { background: theme.colors[bg] ? theme.colors[bg] : bg }),
    ...(color && { color: theme.colors[color] ? theme.colors[color] : color }),
  };
};


type FlexGapProps = {
  gap?: string;
};

const flexGap = (props: FlexGapProps) => {
  const { gap } = props;
  return { gap };
};


export type BoxProps = SpaceProps &
  WidthProps &
  HeightProps &
  TextAlignProps &
  DisplayProps &
  MaxWidthProps &
  OverflowProps &
  ColorProps;

export const Box = styled.div<BoxProps>`
  ${space} ${width} ${height} ${textAlign} ${display} ${maxWidth} ${overflow} ${color}
`;

export type FlexProps = SpaceProps &
  WidthProps &
  HeightProps &
  FlexDirectionProps &
  FlexWrapProps &
  JustifyContentProps &
  AlignItemsProps &
  FlexShorthand &
  FlexGapProps &
  ColorProps;

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${space} ${width} ${height} ${flexDirection} ${flexWrap} ${justifyContent} ${alignItems} ${flex} ${flexGap} ${color}
`;

export type ButtonProps = SpaceProps &
  WidthProps &
  HeightProps &
  BorderRadiusProps &
  ColorProps;

export const Button = styled.button<ButtonProps>`
  border: none;
  padding: 25px 15px;
  border-radius: 10px;
  cursor: pointer;
  ${space} ${width} ${height} ${borderRadius} ${color}
`;

type TextProps = FontSizeProps &
  FontWeightProps &
  LineHeightProps &
  SpaceProps &
  TextAlignProps &
  DisplayProps &
  ColorProps;

export const Text = styled.p<TextProps>`
  ${fontSize} ${fontWeight} ${lineHeight} ${space} ${display} ${textAlign} ${color}
`;

export const TextSpan = styled.span<TextProps>`
  ${fontSize} ${fontWeight} ${lineHeight} ${space} ${display} ${textAlign} ${color}
`;

export const Image = styled.img<SpaceProps & WidthProps & HeightProps>`
  ${space} ${width} ${height}
`;

import { type SalesforceRepo } from './salesforce.repository';
import getSalesforceAccounts from '../../../DataSource/sync/salesforce/getSalesforceAccounts';
import salesforceAuth from '@/src-v2/Data/DataSource/sync/salesforce/salesforceAuth';
import deleteSalesforceAccountAsync from '@/src-v2/Data/DataSource/sync/salesforce/deleteSalesforceAccount';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type Account } from '@/src/pages/Settings/Shared/Entities/Account';

export function SalesforceRepository(): SalesforceRepo {
  return {
    async getSalesforceAccounts(organizationId: string, userId: string): Promise<ApiResponse<Account[]>> {
      const { data, error, success, status }: ApiResponse<Account[]> = await getSalesforceAccounts(
        userId,
        organizationId,
      );

      if (data == null || error != null) {
        return { error, success, status };
      }

      return { data, success: true, status };
    },

    async salesforceAuth(organizationId: string, userId: string): Promise<ApiResponse<string>> {
      const { data, error, success, status }: ApiResponse<string> = await salesforceAuth(organizationId, userId);

      if (data == null || error != null) {
        return { error, success, status };
      }

      return { data, success: true, status };
    },

    async deleteAccount(organizationId: string, userId: string, accountId: string): Promise<ApiResponse<boolean>> {
      const { data, error, success, status }: ApiResponse<boolean> = await deleteSalesforceAccountAsync(
        organizationId,
        userId,
        accountId,
      );

      if (data === null || error !== null) {
        return { error, success, status };
      }

      return { data, success: true, status };
    },
  };
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect, useContext, type ReactElement } from 'react';
import { showUIToast } from '@/src/core/ui/UIToast';
import DeleteAccountModal from '@/src/pages/Settings/Shared/Components/DeleteAccountModal';
import IntegrationUser from '@/src/pages/Settings/Shared/Components/integrations/IntegrationUser.component';
import salesforceLogo from '@/src/assets/icons/salesforce.svg';
import Loader from '@/src/core/ui/UILoader';
import styled from 'styled-components';
import { UserProviderContext } from '@/src/components/app/UserProvider';
import { type Account, AccountType } from '@/src/pages/Settings/Shared/Entities/Account';
import { BaseField, BaseInput, BaseLabel } from '@/src/core/ui/base/FormElements';
import { UIButton, UIMiniButton } from '@/src/core/ui/UIElements';
import { type SalesforceApp } from '@/src/types/SalesforceTypes';
import { Flex } from '@/src/core/ui';
import routes from '@/src/constants/routes';
import axios from 'axios';
import saveSalesforceConfigAsync from '@/src/api/Sync/saveSalesforceConfigAsync';
import getSalesforceConfigAsync from '@/src/api/Sync/getSalesforceConfigAsync';
import deleteSalesforceAccountAsync from '@/src/api/Sync/deteleSalesforceAccountAsync';

const SalesforceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: 395px;
`;

const IntegrationsList = styled.div<{
  scrollStartingAt: number;
}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  ${({ scrollStartingAt }) =>
    scrollStartingAt > 0 &&
    `
    max-height: calc(82px * ${scrollStartingAt} + (.5rem * ${scrollStartingAt}));
    overflow-y: scroll;
  `}
`;

export const SalesForceView = (): ReactElement => {
  const { currentUser } = useContext(UserProviderContext);
  const [salesforceApp, setSalesforceApp] = useState<SalesforceApp>({
    id: '',
    appId: '',
    accessKey: '',
    accessSecret: '',
    url: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    organizationId: '',
    userId: '',
    callbackUrl: '',
  });
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [state, dispatch] = useState<{
    ready: boolean;
    configuring: boolean;
    message: string;
    loading: boolean;
    loadingMessage: string;
    hasConfig: boolean;
  }>({
    ready: true,
    configuring: false,
    message: 'Use button above to connect Salesforce.',
    loading: true,
    loadingMessage: 'Loading linked accounts',
    hasConfig: false,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    nonce: '',
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const { salesforceConfig } = await getSalesforceConfigAsync(currentUser.userId, currentUser.organizationId);
        if (salesforceConfig != null) {
          setSalesforceApp(salesforceConfig);
          dispatch((state) => ({
            ...state,
            hasConfig:
              salesforceConfig.appId !== '' &&
              salesforceConfig.accessKey !== '' &&
              salesforceConfig.accessSecret !== '',
          }));
        } else {
          showUIToast({
            type: 'error',
            text: 'An error occurred while getting Salesforce configuration, please try later.',
          });
          dispatch((state) => ({ ...state, hasConfig: false }));
        }
      } catch (error) {
        showUIToast({
          type: 'error',
          text: 'An error occurred while getting Salesforce configuration, please try later.',
        });
        dispatch((state) => ({ ...state, hasConfig: false }));
        console.error(error);
      }
    };

    void fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (accounts.length === 0) {
      changeMessage('No accounts linked.');
    } else {
      changeMessage(`You have ${accounts.length} ${accounts.length === 1 ? 'account' : 'accounts'} linked.`);
    }
  }, [accounts.length]);

  const handleConnect = async (): Promise<void> => {
    try {
      const url = `${routes.post.salesforce.oAuthLogin}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.post<{ url: string }>(url);
      if (res.status === 201) {
        window.open(res.data.url, 'salesforcePopup', `width=${500},height=${500}`);
        getAccountsInterval();
        showUIToast({
          type: 'default',
          text: 'Salesforce connected',
        });
        dispatch((state) => ({
          ...state,
          configuring: true,
        }));
        return;
      }
    } catch (err) {
      salesforceError();
    }
  };

  const salesforceError = (): void => {
    showUIToast({
      type: 'error',
      text: "Can't configure Salesforce right now, please try again later.",
    });
    dispatch((state) => ({
      ...state,
      ready: false,
      message: "Can't configure Salesforce right now, please try again later.",
    }));
  };

  const changeMessage = (message: string): void => {
    dispatch((state) => ({ ...state, message }));
  };

  const handleCancel = (): void => {
    dispatch((state) => ({
      ...state,
      configuring: false,
      message: 'Use button above to connect Salesforce.',
    }));
  };

  const getAccounts = async (wipeInterval?: () => void): Promise<void> => {
    dispatch((state) => ({
      ...state,
      loading: false,
    }));
    try {
      setAccounts([]);
      const listenUrl = `${routes.get.salesforce.getLinkedAccounts}/${currentUser.organizationId}/${currentUser.userId}`;
      const res = await axios.get<Account[]>(listenUrl);
      if (res.status === 202) {
        showUIToast({
          type: 'info',
          text: 'Salesforce syncing in progress.',
        });
        dispatch((state) => ({
          ...state,
          configuring: true,
          message: 'Configuration in process.',
        }));
        return;
      }
      if (res.status === 204) {
        if (wipeInterval != null) wipeInterval();
        dispatch((state) => ({
          ...state,
          configuring: false,
        }));
        showUIToast({
          type: 'info',
          text: 'Salesforce synced.',
        });
        changeMessage('Nothing was configured.');
        return;
      }
      if (res.status === 200) {
        if (wipeInterval != null) wipeInterval();
        const amount = res.data.length > 0 ? res.data.length : 0;
        changeMessage(`You have ${amount} ${amount > 1 ? 'accounts' : 'account'} linked.`);
        dispatch((state) => ({
          ...state,
          loading: false,
          configuring: false,
        }));
        showUIToast({
          type: 'info',
          text: `Salesforce Custom linked with ${amount} ${amount > 1 ? 'accounts' : 'account'}.`,
        });
        setAccounts(res.data ?? []);
        return;
      }
    } catch (err) {
      if (wipeInterval != null) wipeInterval();
      dispatch((state) => ({
        ...state,
        loading: false,
      }));
      console.log(err);
      changeMessage('No accounts linked.');
    }
  };

  const getAccountsInterval = (): void => {
    const interval = setInterval(() => {
      getAccounts(() => {
        clearInterval(interval);
      }).catch((error) => {
        console.error('Error while fetching accounts', error);
        clearInterval(interval);
      });
    }, 100);
  };

  const handleInputChange = (event: { target: { name: string; value: string } }): void => {
    const { name, value } = event.target;
    setSalesforceApp({ ...salesforceApp, [name]: value });
  };

  const handleSave = async (): Promise<void> => {
    try {
      const { salesforceConfig } = await saveSalesforceConfigAsync(
        currentUser.organizationId,
        currentUser.userId,
        salesforceApp,
      );

      if (salesforceConfig != null) {
        setSalesforceApp({ ...salesforceApp, ...salesforceConfig });
        showUIToast({
          type: 'success',
          text: 'Salesforce configuration saved.',
        });
        dispatch((state) => ({ ...state, hasConfig: true }));
      } else {
        showUIToast({
          type: 'error',
          text: 'Error saving Salesforce configuration, please try again later.',
        });
        dispatch((state) => ({
          ...state,
          hasConfig: false,
          message: 'An error occurred while saving the configuration',
        }));
      }
    } catch (error) {
      console.error('Error saving Salesforce config:', error);
      showUIToast({
        type: 'error',
        text: 'An unexpected error occurred while saving Salesforce configuration.',
      });
    }
  };

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  const removeSalesforceUser = async (nonce: string): Promise<void> => {
    const { success, error } = await deleteSalesforceAccountAsync(
      currentUser.organizationId,
      currentUser.userId,
      nonce,
    );

    if (success) {
      const newUsers = accounts.filter((elem) => elem.nonce !== (nonce != null ? nonce : ''));
      setAccounts(newUsers);
      showUIToast({
        type: 'info',
        text: 'Salesforce account removed.',
      });
      setDeleteModal({ isOpen: false, nonce: '' });
    } else if (error?.message !== '' && error?.message != null) {
      showUIToast({
        type: 'error',
        text: 'Error removing Salesforce account.',
      });
    }
  };
  /* eslint-enable @typescript-eslint/strict-boolean-expressions */

  useEffect(() => {
    void getAccounts();
  }, [currentUser]);

  return (
    <SalesforceContainer>
      <DeleteAccountModal
        isOpen={deleteModal.isOpen}
        onClose={() => {
          setDeleteModal({ isOpen: false, nonce: '' });
        }}
        handleDelete={() => {
          removeSalesforceUser(deleteModal.nonce).catch((err) => {
            console.error('Error removing Salesforce user:', err);
          });
        }}
      />
      {state.loading ? (
        <Loader message={state.loadingMessage} />
      ) : (
        <>
          <BaseField>
            <BaseLabel>
              Add your Salesforce External App Config.{' '}
              <a
                target="_blank"
                href="https://help.salesforce.com/s/articleView?id=analytics.bi_app_create.htm&type=5"
                rel="noreferrer"
              >
                Learn how to set up developer accounts.
              </a>
            </BaseLabel>
          </BaseField>
          <BaseField>
            <BaseLabel title="External Client App Name">External Client App Name</BaseLabel>
            <BaseInput
              data-qa-id={'scriptInput'}
              type="text"
              name="appId"
              value={salesforceApp.appId !== '' ? salesforceApp.appId : ''}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Consumer Key">Consumer Key</BaseLabel>
            <BaseInput
              data-qa-id={'scriptInput'}
              type="text"
              name="accessKey"
              value={salesforceApp.accessKey !== '' ? salesforceApp.accessKey : ''}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Comsumer Secret">Consumer Secret</BaseLabel>
            <BaseInput
              data-qa-id={'scriptInput'}
              type="text"
              name="accessSecret"
              value={salesforceApp.accessSecret !== '' ? salesforceApp.accessSecret : ''}
              onChange={handleInputChange}
            />
          </BaseField>
          <BaseField>
            <BaseLabel title="Redirect URL (OAuth)">Redirect URL (OAuth)</BaseLabel>
            <BaseInput
              data-qa-id={'scriptInput'}
              type="text"
              name="callbackUrl"
              readOnly={true}
              value={salesforceApp.callbackUrl !== '' ? salesforceApp.callbackUrl : ''}
            />
          </BaseField>
          <Flex flexDirection="row" justifyContent="flex-end" width="100%">
            <UIButton
              onClick={() => {
                handleSave().catch((error) => {
                  console.error('Error in saving:', error);
                });
              }}
            >
              Save
            </UIButton>
          </Flex>
          {state.hasConfig && (
            <>
              <UIMiniButton
                className="salesforce"
                style={{
                  justifySelf: 'center',
                  backgroundColor: 'white',
                  padding: '65px',
                  backgroundImage: `url(${salesforceLogo})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                disabled={state.configuring}
                onClick={() => {
                  void handleConnect();
                }}
              ></UIMiniButton>
              {state.configuring && <UIMiniButton onClick={handleCancel}>Cancel Configuration</UIMiniButton>}
              <IntegrationsList scrollStartingAt={accounts.length > 10 ? 5 : 0}>
                {accounts.map((user) => {
                  const { id, attributes, email, nonce } = user;
                  return (
                    <IntegrationUser
                      key={nonce}
                      remove={(nonce, type) => {
                        setDeleteModal({ isOpen: true, nonce });
                      }}
                      id={id}
                      type={AccountType.Salesforce}
                      email={email}
                      nonce={nonce}
                      {...attributes}
                    />
                  );
                })}
              </IntegrationsList>
              <div>{state.message}</div>
            </>
          )}
        </>
      )}
    </SalesforceContainer>
  );
};

export type { SalesforceApp };

import { type ChangeEvent, type FC, type FormEvent } from 'react';
import React from 'react';
import { Button } from '@components/ui/button';
import { IconSearch } from '@tabler/icons-react';

interface SearchBarComponentProps {
  changeFunction: (e: ChangeEvent<HTMLInputElement>) => void;
  submitFunction: (e: FormEvent) => void;
}

const SearchBarComponent: FC<SearchBarComponentProps> = ({ changeFunction, submitFunction }) => {
  return (
    <form className="flex gap-2 max-[1400px]:max-w-[100%] pb-1" onSubmit={submitFunction}>
      <input
        id="inputSearchBarDashboard"
        className="flex-1 shrink border border-app-gray-300 placeholder-app-gray-400 px-2"
        data-qa-id="inputSearchBarDashboard"
        placeholder="Search by sdr, contact list name or organization"
        onChange={changeFunction}
      />
      <Button data-qa-id="searchBtn" className="flex-none w-9" type="submit" size="icon">
        <IconSearch />
      </Button>
    </form>
  );
};
export default SearchBarComponent;

import DashboardRepository from '@repository/contacts-nest/dashboard/dashboardRepository';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type PaginatedDashboardDetailResponse } from '@datasource/contacts-nest/dashboard/entities/dashboardSessionDetail';
import { ContactsRepository } from '@repository/contacts-nest/contacts/contactsRepository';
import { type ContactInfo } from '@datasource/contacts-nest/contacts/entities/contact.entity';
import { type RecordingsWithLink } from '@datasource/contacts-nest/dashboard/getSessionRecordings';

interface DashboardDetailsUseCaseProps {
  getDashboardDetails: (
    callSessionId: string,
    userId: string,
    page: number,
    limit: number,
  ) => Promise<ApiResponse<PaginatedDashboardDetailResponse>>;
  getContactById: (contactId: string) => Promise<ApiResponse<ContactInfo>>;
  playSessionRecordings: (callId: string, userId: string) => Promise<ApiResponse<RecordingsWithLink>>;
}

function DashboardDetailsUseCase(): DashboardDetailsUseCaseProps {
  const { getDashboardDetails, getSessionRecordings } = DashboardRepository();
  const { getContactById } = ContactsRepository();

  return {
    async getDashboardDetails(callSessionId: string, userId: string, page: number, limit: number) {
      const { data, success, error }: ApiResponse<PaginatedDashboardDetailResponse> = await getDashboardDetails(
        callSessionId,
        userId,
        page,
        limit,
      );
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getContactById(contactId) {
      const { data, success, error } = await getContactById(contactId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async playSessionRecordings(callId: string, userId: string) {
      const { data, success, error } = await getSessionRecordings(callId, userId);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

export default DashboardDetailsUseCase;

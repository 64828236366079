import axios from 'axios';
import { type ApiResponse } from '@/src/types/ApiResponse';
import routes from '@/src/constants/routes';

export class CallRecording {
  id!: string;
  twilioRecordingId!: string;
  twilioCallId!: string;
  dateCreated!: Date;
  dateUpdated!: Date;
  startTime!: Date;
  duration!: string;
  recordingUrl!: string;
  label!: string;
  source!: string;
  createdAt!: Date;
  updatedAt!: Date;
}

export class RecordingsWithLink {
  agentCallRecording!: CallRecording | null;
  sdrCallRecording!: CallRecording | null;
}

async function getSessionRecordingsAsync(callId: string, userId: string): Promise<ApiResponse<RecordingsWithLink>> {
  try {
    const dashboardRecordingsRoute = `${routes.get.dashboard.recordings}/${callId}/user/${userId}`;
    const recordings: ApiResponse<RecordingsWithLink> = await axios.get(dashboardRecordingsRoute);

    return { data: recordings.data, success: true };
  } catch {
    return { error: { message: `Could not get Recordings for this session-id: ${callId}` }, success: false };
  }
}
export default getSessionRecordingsAsync;

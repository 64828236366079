import { useState } from 'react';
import type FeaturesDto from '@usecase/feature-flags/dto/features.dto';
import { type UpdateFeatureFlagsDto } from '@datasource/sync/feature-flags/dto/update-feature-flags.dto';
import { LoadingState } from '@/src/types/LoadingStates.type';
import FeatureFlagsUseCase from '@usecase/feature-flags/FeatureFlagsUseCase';

const { IDLE, LOADING, ERROR, SUCCESS } = LoadingState;

interface ViewState {
  featureFlags: FeaturesDto;
  organizationId: string;
}

interface ViewLoadingState {
  getFeatureFlags: LoadingState;
  updateFeatureFlags: LoadingState;
}

const INITIAL_VIEW_STATE: ViewState = {
  featureFlags: {
    outreach: false,
    hubspot: false,
    hubspotCustom: false,
    fourMativ: false,
    numbersPool: false,
    accountSettings: false,
    callerId: false,
    contactsFilter: false,
    userSettings: false,
    dashboardDetails: false,
    callRecordings: false,
    salesforce: false,
  },
  organizationId: 'organizationIdNotProvided',
};

const INITIAL_LOADING_STATE: ViewLoadingState = {
  getFeatureFlags: IDLE,
  updateFeatureFlags: IDLE,
};

export interface FeatureFlagsViewModelReturn {
  viewState: ViewState;
  viewLoadingState: ViewLoadingState;
  getFlags: (organizationId: string) => Promise<void>;
  updateFlags: (organizationId: string, updateFeatureFlagsDto: UpdateFeatureFlagsDto) => Promise<void>;
}

export default function FeatureFlagsViewModel(): FeatureFlagsViewModelReturn {
  const { getFeatureFlags, updateFeatureFlags, utils } = FeatureFlagsUseCase();
  const [viewState, setViewState] = useState<ViewState>(INITIAL_VIEW_STATE);
  const [viewLoadingState, setViewLoadingState] = useState<ViewLoadingState>(INITIAL_LOADING_STATE);

  async function getFlags(organizationId: string): Promise<void> {
    setViewLoadingState({
      ...viewLoadingState,
      getFeatureFlags: LOADING,
    });
    const featureFlags = await getFeatureFlags(organizationId);

    if (featureFlags === undefined) {
      setViewLoadingState({
        ...viewLoadingState,
        getFeatureFlags: ERROR,
      });
      return;
    }

    setViewLoadingState({
      ...viewLoadingState,
      getFeatureFlags: SUCCESS,
    });

    setViewState({ ...viewState, featureFlags, organizationId });
  }

  async function updateFlags(organizationId: string, updateFeatureFlagsDto: UpdateFeatureFlagsDto): Promise<void> {
    setViewLoadingState({
      ...viewLoadingState,
      updateFeatureFlags: LOADING,
    });

    const featureFlags = await updateFeatureFlags(organizationId, updateFeatureFlagsDto);

    if (featureFlags === undefined) {
      setViewLoadingState({
        ...viewLoadingState,
        updateFeatureFlags: ERROR,
      });
      return;
    }

    setViewState({ ...viewState, featureFlags: utils.transformOnlyFlags(featureFlags), organizationId });

    setViewLoadingState({
      ...viewLoadingState,
      updateFeatureFlags: SUCCESS,
    });
  }

  return {
    viewState,
    getFlags,
    updateFlags,
    viewLoadingState,
  };
}

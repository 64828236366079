import EdgeDashboardRepository from '@repository/edge/dashboard/edgeDashboardRepository';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type DashboardResponse } from '@datasource/contacts-nest/dashboard/entities/dashboardResponse';
import DashboardRepository from '@repository/contacts-nest/dashboard/dashboardRepository';
import { type DashboardSearchResponse } from '@datasource/edge/dashboard/entities/dashboardSearchResponse';
import { UsersRepository } from '@repository/edge/users/usersRepository';
import OrganizationsRepository from '@repository/edge/organizations/organizationsRepository';
import { type ActiveOrganizations } from '@datasource/edge/organizations/organizations';
import { type SdrResponse } from '@datasource/edge/users/entities/user.entity';
import { CallSessionRepository } from '@repository/contacts-nest/callSession/callSessionRepository';

interface DashboardUseCaseProps {
  getDashboardData: (
    sdrId: string,
    params: {
      page: number;
      pageLimit: number;
      searchQuery?: string;
      sdrIds?: string[];
      organizationIds?: string[];
    },
  ) => Promise<ApiResponse<DashboardResponse>>;
  getReportData: (callSessionId: string, type: string) => Promise<ApiResponse<string>>;
  searchInfoByName: (query: string) => Promise<ApiResponse<DashboardSearchResponse>>;
  getAllActiveSDRs: (
    userId: string,
    userIds: string[] | null,
    organizationId: string | null,
    selectedOrgId: string | null,
  ) => Promise<ApiResponse<SdrResponse[]>>;
  listAllOrganizations: () => Promise<ApiResponse<ActiveOrganizations[]>>;
}

function DashboardUseCase(): DashboardUseCaseProps {
  const { getDashboardData } = DashboardRepository();
  const { getSessionReport } = CallSessionRepository();
  const { searchInfoByName } = EdgeDashboardRepository();
  const { getAllActiveSdrs } = UsersRepository();
  const { getAllOrganizations } = OrganizationsRepository();
  return {
    async getDashboardData(sdrId: string, params) {
      const { data, success, error } = await getDashboardData(sdrId, params);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async searchInfoByName(query: string): Promise<ApiResponse<DashboardSearchResponse>> {
      const { data, success, error } = await searchInfoByName(query);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },

    async getAllActiveSDRs(
      userId: string,
      userIds: string[] | null,
      organizationId: string | null,
      selectedOrgId: string | null,
    ) {
      const { data, success, error } = await getAllActiveSdrs(userId, userIds, organizationId, selectedOrgId);
      if (data === null || data === undefined) {
        return { error, success };
      }
      return { data, success: true };
    },

    async listAllOrganizations(): Promise<ApiResponse<ActiveOrganizations[]>> {
      const { data, success, error } = await getAllOrganizations();
      if (data == null || error != null) {
        return { error, success };
      }
      return { data, success: true };
    },

    async getReportData(callSessionId: string, type: string) {
      const { data, success, error } = await getSessionReport(callSessionId, type);
      if (data == null || error != null) {
        return { error, success };
      }

      return { data, success: true };
    },
  };
}

export default DashboardUseCase;

import React, { type Dispatch, type FC, type SetStateAction, useRef, useState } from 'react';
import { IconPlayerPauseFilled, IconPlayerPlayFilled, IconX } from '@tabler/icons-react';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@components/ui/alert-dialog';
import { Button } from '@components/ui/button';
import { toast } from '@components/ui/use-toast';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

interface RecordingPlayerProps {
  trigger: React.JSX.Element;
  sdrAudio: string;
  agentAudio: string;
  sdrAudioDuration: string;
  agentAudioDuration: string;
  opened: boolean;
  setOpenedModal: Dispatch<SetStateAction<boolean>>;
  setSdrAudio: Dispatch<SetStateAction<string>>;
  setAgentAudio: Dispatch<SetStateAction<string>>;
}

const RecordingPlayerComponent: FC<RecordingPlayerProps> = ({
  trigger,
  sdrAudio,
  agentAudio,
  sdrAudioDuration,
  agentAudioDuration,
  opened,
  setOpenedModal,
  setSdrAudio,
  setAgentAudio,
}) => {
  const [isSdrPlaying, setSdrPlaying] = useState(false);
  const [isAgentPlaying, setAgentPlaying] = useState(false);
  const sdrRef = useRef<HTMLAudioElement>(null);
  const agentRef = useRef<HTMLAudioElement>(null);

  const cellBorder = 'border-2 border-secondary';

  const handleSdrEnded = (): void => {
    setSdrPlaying(false);
  };

  const handleAgentEnded = (): void => {
    setAgentPlaying(false);
  };

  const toggleSdrPlayPause = (): void => {
    if (isAgentPlaying) {
      agentRef?.current?.pause();
      setAgentPlaying(false);
    }
    if (isSdrPlaying) {
      sdrRef?.current?.pause();
      setSdrPlaying(false);
      toast({ variant: 'default', title: 'Paused', description: 'Call Recording was paused' });
    } else {
      void sdrRef?.current?.play();
      setSdrPlaying(true);
      toast({ variant: 'default', title: 'Playing', description: 'Playing Call Recording' });
    }
  };

  const toggleAgentPlayPause = (): void => {
    if (isSdrPlaying) {
      sdrRef?.current?.pause();
      setSdrPlaying(false);
    }
    if (isAgentPlaying) {
      agentRef?.current?.pause();
      setAgentPlaying(false);
      toast({ variant: 'default', title: 'Paused', description: 'Call Recording was paused' });
    } else {
      void agentRef?.current?.play();
      setAgentPlaying(true);
      toast({ variant: 'default', title: 'Playing', description: 'Playing Call Recording' });
    }
  };

  return (
    <AlertDialog open={opened}>
      <AlertDialogTrigger asChild>
        <div className="flex justify-center max-w-12 cursor-pointer">{trigger}</div>
      </AlertDialogTrigger>
      <AlertDialogContent className="px-2 py-4">
        <AlertDialogHeader className={'flex items-center'}>
          <div className="flex flex-row items-center gap-60 justify-between">
            <AlertDialogTitle className="text-app-blue-light">Play recordings</AlertDialogTitle>
            <AlertDialogCancel className="border-none">
              <IconX
                color="hsl(var(--primary))"
                onClick={() => {
                  setOpenedModal(false);
                  setSdrAudio('');
                  setAgentAudio('');
                  setSdrPlaying(false);
                  setAgentPlaying(false);
                }}
              />
            </AlertDialogCancel>
          </div>
        </AlertDialogHeader>
        <div className="flex flex-row p-4 justify-center">
          <table className="w-3/4 border-t-gray-500">
            <thead>
              <tr className="text-center">
                <td className={cellBorder}>Call from</td>
                <td className={cellBorder}>Duration (seconds)</td>
                <td className={cellBorder}>Play</td>
              </tr>
            </thead>
            <tbody>
              {agentAudio !== '' && (
                <tr className="text-center">
                  <td className={cellBorder}>
                    <b>Agent</b>
                  </td>
                  <td className={cellBorder}>
                    <h3>{moment.duration(agentAudioDuration, 'seconds').format()}</h3>
                  </td>
                  <td className={cellBorder}>
                    <audio id="agentRecording" ref={agentRef} onEnded={handleAgentEnded}>
                      <source src={agentAudio} />
                    </audio>
                    <Button
                      size="sm"
                      className="rounded-full"
                      onClick={() => {
                        toggleAgentPlayPause();
                      }}
                    >
                      {isAgentPlaying ? (
                        <IconPlayerPauseFilled color="white" size={16} />
                      ) : (
                        <IconPlayerPlayFilled color="white" size={16} />
                      )}
                    </Button>
                  </td>
                </tr>
              )}
              {sdrAudio !== '' && (
                <tr className="text-center">
                  <td className={cellBorder}>
                    <b>User</b>
                  </td>
                  <td className={cellBorder}>
                    <h3>{moment.duration(sdrAudioDuration, 'seconds').format()}</h3>
                  </td>
                  <td className={cellBorder}>
                    <audio id="sdrRecording" ref={sdrRef} onEnded={handleSdrEnded}>
                      <source src={sdrAudio} />
                    </audio>
                    <Button
                      id="sdrRecordingButton"
                      size="sm"
                      className="rounded-full"
                      onClick={() => {
                        toggleSdrPlayPause();
                      }}
                    >
                      {isSdrPlaying ? (
                        <IconPlayerPauseFilled color="white" size={16} />
                      ) : (
                        <IconPlayerPlayFilled color="white" size={16} />
                      )}
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RecordingPlayerComponent;

import React, {
  useState,
  useContext,
  ChangeEvent, useEffect, useRef,
} from "react";
import { useAppContext } from "../../libs/contextLib";
import axios from "axios";
import routes from "../../constants/routes";
import TendrilSlogan from "../../../public/assets/icons/tendril_slogan.svg";
import { UserProviderContext } from "../../components/app/UserProvider";
import { createSessionUser } from "../../core/utils/sessionRefresher";
import LoginLeftColumn from "./LoginLeftColumn";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import {
  LoginGrid,
  LoginCell,
  LoginColumn,
  LoginErrorMessage,
  LoginAnchor,
  LoginForm,
  LoginInputText,
  LoginButton,
  Icon,
  Row,
} from "./LoginElements";
import { featuresStore } from "../../components/app/FeaturesProvider";
import TagManager from "react-gtm-module";
import getType from "../../utils/getUserType";
import { showUIToast } from "../../core/ui/UIToast";
import login from "../../api/Auth/login";
import {useNavigate} from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from '../../constants/routesConnect';
import { CurrentUser } from '../../types';
import { useLogger } from "../../components/app/LoggerProvider";
import { AuthUseCase } from "../../../src-v2/Domain/UseCase/auth/AuthUseCase";
import { useAuth } from "../../components/app/AuthProvider";
import {toast} from "../../../src-v2/Presentation/Common/Components/ui/use-toast";

const window = globalThis as any;

type TodoErrorResponse = {
  error: string;
};

const Login: React.FC = () => {
  const { setIsAuthenticated } = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordShown, setPasswordShown] = useState<boolean>(false);
  const { setCurrentUser, currentUser } = useContext(UserProviderContext);
  const { fetchFeatures } = useContext(featuresStore);
  const navigate = useNavigate();
  const { logger, logWithToken } =  useLogger();
  const loggerRef = useRef(logger);
  const validateForm = () => {
    const valid = email.length > 0 && password.length > 0;
    return valid;
  };

  const togglePassword = () => {
    setPasswordShown(!isPasswordShown);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = e as any satisfies EventTarget & HTMLInputElement;
    setEmail(value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = e as any satisfies EventTarget & HTMLInputElement;
    setPassword(value);
  };

  const {startSession} = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    try {

      startSession({
        email: email,
        password: password
      });

      const { authToken } = await login(email, password);

      const user = createSessionUser(authToken as string);

      window.localStorage.setItem("target", authToken as string);
      
      window.localStorage.setItem("user", JSON.stringify(user));

      if (!user) {
        navigate(`/${PublicRoutes.LOGIN}`, {replace: true});
      }

      const userSession: CurrentUser = JSON.parse(window.localStorage.getItem('user') || '{}')
      setCurrentUser(userSession);
      setIsAuthenticated(true);
      fetchFeatures(user.organizationId);

      const { data } = await AuthUseCase().getLoggerTokenAsync();

      logWithToken?.(data, `User was logged in: ${user.email}`,  {
        componentName: Login.name,
        email: user.email
      });

      const userTypeAdmin = userSession.type.some(
          (item) => item.type === 'admin'
      );

      const userTypeSdr = userSession.type.some(
          (item) => item.type === 'sdr'
      );

      navigate(userTypeAdmin && userTypeSdr ? `/${PrivateRoutes.HOME}` : userTypeAdmin ? `/${PrivateRoutes.DASHBOARD_V2}` : userTypeSdr ? `/${PrivateRoutes.HOME}` : `/${PrivateRoutes.DIALER}`, {replace: true});

      TagManager.dataLayer({
        dataLayer: {
          event: "login",
          path: window.location.pathname,
          userId: user.userId,
          organizationId: user.organizationId,
          role: getType(user.type),
        },
      });

      const healthResponse = await axios.get(routes.health.sync);
      console.log("SYNC response");
      console.log(JSON.stringify(healthResponse));
      console.log(healthResponse.data);

      const healthResponseContactsNest = await axios.get(
        routes.health.contactsNest
      );
      console.log("CONTACTS NEST response");
      console.log(JSON.stringify(healthResponseContactsNest));
      console.log(healthResponseContactsNest.data);
    } catch (error) {
      setErrors(true);
      if (axios.isAxiosError(error) && error.response) {
        // Request made and server responded
        setErrorMessage((error.response?.data as TodoErrorResponse).error);
        showUIToast({
          type: "warning",
          text:
            (error.response?.data as TodoErrorResponse).error ||
            "Login requires attention. If the problem persist, please contact customer support.",
        });
      } else {
        // The request was made but no response was received
        setErrorMessage("Something went wrong, please try later");
        showUIToast({
          type: "error",
          text: "Something went wrong, try again later.",
        });
      }
    }
  };

  useEffect(() => {
    if (window.location.search === '?e=1') {
      toast({
        title: 'Forbidden action.',
        description: 'A duplicated session was detected. Logged out.',
        variant: 'destructive'
      })

      loggerRef.current.error(`User was dropped out, due to session duplication. User: ${currentUser.email}`, {
        componentName: Login.name,
        userId: currentUser.email,
      });
    }
    if (window.location.search === '?e=2') {
      toast({
        title: 'Forbidden action.',
        description: 'A duplicated tab session was detected. Logged out.',
        variant: 'destructive'
      })

      loggerRef.current.error(`The session was closed by another session opened in another tab, user: ${currentUser.email}`, {
        componentName: Login.name,
        userId: currentUser.email,
      });
    }
  }, []);

  return (
    <LoginGrid columns={["35%", "1fr"]}>
      <LoginLeftColumn />
      <LoginCell backgroundColor={"#EFEFEF"} color="black">
        <LoginColumn
          alignItems="center"
          justifyContent="center"
          gap="2rem"
          padding="3em 0"
        >
          <img src={TendrilSlogan} alt="Tendril Slogan" width="312" />
          <LoginForm onSubmit={handleSubmit} columns={["60%", "1fr"]}>
            {errors && <LoginErrorMessage>{errorMessage}</LoginErrorMessage>}
            <Row>
              <LoginInputText
                type="email"
                placeholder={"Email Address"}
                value={email}
                onChange={handleEmailChange}
                fontSize="1.625rem"
                data-qa-id="input-email"
              />
            </Row>
            <Row>
              <Icon onClick={togglePassword}>
                {!isPasswordShown ? (
                  <FA icon="eye" color="#95979A" size="xl" />
                ) : (
                  <FA icon="eye-slash" color="#95979A" size="xl" />
                )}
              </Icon>

              <LoginInputText
                type={isPasswordShown ? "text" : "password"}
                placeholder={"Password"}
                value={password}
                onChange={handlePasswordChange}
                fontSize="1.625rem"
                data-qa-id="input-password"
              />
            </Row>
            <LoginButton
              type="submit"
              value="Login"
              disabled={!validateForm()}
              fontSize="1.625rem"
              data-qa-id="login-button"
            />
            {/* This feature is temporarily disabled due to improvements in the authentication system */}
            {/* <LoginAnchor href="/password/recover" className="right gray">
              Forgot your password?
            </LoginAnchor> */}
          </LoginForm>
        </LoginColumn>
      </LoginCell>
    </LoginGrid>
  );
};

export default Login;

import React, { useState, createContext, ReactElement } from "react";
import axios from "axios";
import routes from "../../constants/routes";
import { showUIToast } from "../../core/ui/UIToast";

type StateProps = {
  outreach: boolean;
  userSettings: boolean;
  accountSettings: boolean;
  fourMativ: boolean;
  hubspot: boolean;
  hubspotCustom: boolean;
  numbersPool: boolean;
  callerId: boolean;
  contactsFilter: boolean;
  dashboardDetails: boolean;
  callRecordings: boolean;
  salesforce: boolean;
};

type FeaturesResponse = {
  id: string;
  organizationId: string;
  outreach: boolean;
  hubspot: boolean;
  hubspotCustom: boolean;
  userSettings: boolean;
  accountSettings: boolean;
  fourMativ: boolean;
  numbersPool: boolean;
  callerId: boolean;
  contactsFilter: boolean;
  dashboardDetails: boolean;
  callRecordings: boolean;
  salesforce: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

interface ProviderProps {
  featuresState: StateProps;
  fetchFeatures: (orgId: string) => void;
  toggleFeature: (propName: Feature) => void;
  resetAllFeatures: () => void;
}

type Feature =
  | "outreach"
  | "userSettings"
  | "accountSettings"
  | "fourMativ"
  | "hubspot"
  | "hubspotCustom"
  | "numbersPool"
  | "callerId"
  | "contactsFilter"
  | "dashboardDetails"
  | "callRecordings"
  | "salesforce"
  ;

export enum FeatureFlags {
  OUTREACH = "outreach",
  USER_SETTINGS = "userSettings",
  ACCOUNT_SETTINGS = "accountSettings",
  FOUR_MATIV = "fourMativ",
  HUBSPOT = "hubspot",
  HUBSPOT_CUSTOM = "hubspotCustom",
  NUMBERS_POOL = "numbersPool",
  CALLER_ID = "callerId",
  DASHBORD_DETAILS = "dashboardDetails",
  CALL_RECORDINGS = "callRecordings",
  SALESFORCE = "salesforce"
}

const allFeatures: Feature[] = [
  "outreach",
  "userSettings",
  "accountSettings",
  "fourMativ",
  "hubspot",
  "numbersPool",
  "callerId",
  "contactsFilter",
  "dashboardDetails",
  "callRecordings",
  "salesforce"
];

const initialState: StateProps = {
  outreach: false,
  userSettings: false,
  accountSettings: false,
  fourMativ: false,
  hubspot: false,
  numbersPool: false,
  hubspotCustom: false,
  callerId: false,
  contactsFilter: false,
  dashboardDetails: false,
  callRecordings: false,
  salesforce: false
};

const featuresStore = createContext<ProviderProps>({} as ProviderProps);
const { Provider } = featuresStore;

const FeaturesProvider = (props: { children: ReactElement }): React.JSX.Element => {
  const [featuresState, dispatch] = useState(initialState);

  const toggleFeature = (propName: Feature) => {
    dispatch((state) => ({ ...state, [propName]: !state[propName] }));
  };
  const setFeatures = (propNames: Feature[], value: boolean) => {
    const newFeatures = propNames.reduce(
      (acc, curr) => ({ ...acc, [curr]: value }),
      {}
    );
    dispatch((state) => ({ ...state, ...newFeatures }));
  };

  const resetAllFeatures = () => setFeatures(allFeatures, false);

  const fetchFeatures = async (orgId: string) => {
    try {
      const url = `${routes.get.featureToggle.getFeaturesFlagsForOrg}/${orgId}`;
      const res = await axios.get<FeaturesResponse>(url);
      if (res.status === 200) {
        const {
          outreach,
          userSettings,
          accountSettings,
          fourMativ,
          hubspot,
          hubspotCustom,
          numbersPool,
          callerId,
          contactsFilter,
          dashboardDetails,
          callRecordings,
          salesforce
        } = res.data;
        const features = [] as Feature[];
        if (outreach) features.push("outreach");
        if (userSettings) features.push("userSettings");
        if (fourMativ) features.push("fourMativ");
        if (hubspot) features.push("hubspot");
        if (hubspotCustom) features.push("hubspotCustom");
        if (numbersPool) features.push("numbersPool");
        if (callerId) features.push("callerId");
        if (contactsFilter) features.push("contactsFilter");
        if (accountSettings) features.push("accountSettings");
        if (dashboardDetails) features.push("dashboardDetails");
        if (callRecordings) features.push("callRecordings");
        if (salesforce) features.push("salesforce");
        setFeatures(features, true);
        return res;
      }
    } catch (err) {
      showUIToast({
        type: "error",
        text: "Error getting feature flags, try again later.",
      });
    }
  };

  return (
    <Provider
      value={{
        featuresState,
        fetchFeatures,
        toggleFeature,
        resetAllFeatures,
      }}
    >
      {props.children}
    </Provider>
  );
};

export { FeaturesProvider, featuresStore };

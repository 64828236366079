import React, { ReactElement, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import getBuildMetricsAsync from "../../../api/Metric/getBuildMetricsAsync";
import { showUIToast } from "../../../core/ui/UIToast";
import { UserProviderContext } from "../../../components/app/UserProvider";
import { UICol } from "../../../core/ui/UIStructures";
import { UIText } from "../../../core/ui/UIElements";
import Loader from "../../../core/ui/UILoader";
import SummaryItem from "./SummaryItem";
import { motion } from "framer-motion";

// TODO: Replace and remove SVG assets with @fortawesome icons
import Phone from "../../../assets/icons/summary/phone.svg";
import Answer from "../../../assets/icons/summary/answer.svg";
import Transfer from "../../../assets/icons/summary/tranfer.svg";
import Voicemail from "../../../assets/icons/summary/voicemail.svg";
import Cutoff from "../../../assets/icons/summary/cutoof.svg";
import TransferError from "../../../assets/icons/summary/tranfer-errpr.svg";
import NotAnswered from "../../../assets/icons/summary/ban.svg";
import BdBs from "../../../assets/icons/summary/signal.svg";
import Refusal from "../../../assets/icons/summary/phone-slash.svg";
import RefusalTransfer from "../../../assets/icons/summary/users-slash.svg";
import NoLonger from "../../../assets/icons/summary/user-slash.svg";
import Referred from "../../../assets/icons/summary/user-xmark.svg";
import OptOut from "../../../assets/icons/summary/circle-xmark.svg";
import SetAppointment from "../../../assets/icons/summary/calendar-check.svg";
import Gatekeeper from "../../../assets/icons/summary/building-user.svg";
import DialConnect from "../../../assets/icons/summary/percent.svg";
import SDRIcon from "../../../assets/icons/summary/user-tie-solid.svg";
import getCallSessionInfo from "../../../api/CallSession/getCallSessionInfo";
import getUserInfoAsync from "../../../api/Users/getUserInfo";
import getBuildDispositionMetricsAsync from "../../../api/Metric/getBuildDispositionMetricsAsync";
import { PrivateRoutes } from "../../../constants/routesConnect";
import { useNavigate } from "react-router-dom";

export enum SummaryType {
  DISPOSITION = "DISPOSITION",
  CALL_SESSION_FLOW = "CALL_SESSION_FLOW",
  CALL_SESSION_INFO = "CALL_SESSION_INFO",
}

export interface SummaryData {
  text: string;
  value: number | string;
  icon: string;
  type: SummaryType;
}

export interface SummaryContainerProps {
  callSessionId: string;
}

const SummaryContainer = ({
  callSessionId,
}: SummaryContainerProps): ReactElement => {
  const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
  const { currentUser } = useContext(UserProviderContext);
  const [loadingStatus, setLoadingStatus] = useState({
    summary: true,
  });
  const navigate = useNavigate();

  const dispositionData = summaryData.filter(
    (item) => item.type === SummaryType.DISPOSITION
  );

  const callSessionFlowData = summaryData.filter(
    (item) => item.type === SummaryType.CALL_SESSION_FLOW
  );

  const callSessionInfoData = summaryData.filter(
    (item) => item.type === SummaryType.CALL_SESSION_INFO
  );

  useEffect(() => {
    const fetchMetrics = async (callSessionId: string) => {
      try {
        const { data, error } = await getBuildMetricsAsync(callSessionId);

        const metricsDisposition = await getBuildDispositionMetricsAsync(
          callSessionId
        );

        if (
          error ||
          !data ||
          metricsDisposition.error ||
          !metricsDisposition.data
        ) {
          setLoadingStatus((prev) => ({ ...prev, summary: false }));
          showUIToast({
            type: "error",
            text: "Error getting metrics, try again later.",
          });

          const lastPage = localStorage.getItem('lastDashboardPage') ?? '1';
          const lastSearch = localStorage.getItem('lastDashboardSearch') ?? '';

          navigate(`/${PrivateRoutes.DASHBOARD_V2}?search=${lastSearch}&page=${lastPage}`, { replace: true });
          return;
        }

        const totalCalled = data["called"];
        const totalAnswered = data["answered"];
        const totalTransfer = data["transferred"];
        const totalVoicemail = data["voicemail"];
        const totalNotAnswered = data["not_answered"];
        const totalTransferError = data["transfer_error"];
        const totalCutOff = data["cut_off"];

        const totalRTT = metricsDisposition.data["refuse_transfer"];
        const totalBNBS = metricsDisposition.data["bad_number_busy_signal"];
        const totalOO = metricsDisposition.data["opt_out"];
        const totalLVM = metricsDisposition.data["left_vm"];
        const totalPR = metricsDisposition.data["phone_refusal"];
        const totalGC = metricsDisposition.data["gatekeeper_conversation"];
        const totalRAC = metricsDisposition.data["referred_another_contact"];
        const totalNLC = metricsDisposition.data["no_longer_with_company"];
        const totalSA = metricsDisposition.data["set_appointment"];

        const callSessionSDRUsername = await getSDRUsername();

        const expandableData: SummaryData[] = [
          {
            text: "Total calls",
            value: totalCalled,
            icon: Phone,
            type: SummaryType.CALL_SESSION_FLOW,
          },
          {
            text: "Answered",
            value: totalAnswered,
            icon: Answer,
            type: SummaryType.CALL_SESSION_FLOW,
          },
          {
            text: "Transfers",
            value: totalTransfer,
            icon: Transfer,
            type: SummaryType.CALL_SESSION_FLOW,
          },
          {
            text: "Voicemails",
            value: totalVoicemail,
            icon: Voicemail,
            type: SummaryType.CALL_SESSION_FLOW,
          },
          {
            text: "Cut off",
            value: totalCutOff,
            icon: Cutoff,
            type: SummaryType.CALL_SESSION_FLOW,
          },
          {
            text: "Transfer errors",
            value: totalTransferError,
            icon: TransferError,
            type: SummaryType.CALL_SESSION_FLOW,
          },
          {
            text: "Not answered",
            value: totalNotAnswered,
            icon: NotAnswered,
            type: SummaryType.CALL_SESSION_FLOW,
          },
          {
            text: "BD/BS",
            value: totalBNBS,
            icon: BdBs,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "Left VM",
            value: totalLVM,
            icon: Voicemail,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "Phone refusal (Hangup)",
            value: totalPR,
            icon: Refusal,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "Refuse to transfer",
            value: totalRTT,
            icon: RefusalTransfer,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "No longer with company",
            value: totalNLC,
            icon: NoLonger,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "Referred to another contact",
            value: totalRAC,
            icon: Referred,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "Opt Out",
            value: totalOO,
            icon: OptOut,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "Set Appointment",
            value: totalSA,
            icon: SetAppointment,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "Gatekeeper Conversation",
            value: totalGC,
            icon: Gatekeeper,
            type: SummaryType.DISPOSITION,
          },
          {
            text: "SDR",
            value: callSessionSDRUsername,
            icon: SDRIcon,
            type: SummaryType.CALL_SESSION_INFO,
          },
          {
            text: "Dial to Connect Ratio",
            value:
              totalTransfer === 0
                ? "0 %"
                : ((totalTransfer / totalCalled) * 100).toFixed(2) + " %",
            icon: DialConnect,
            type: SummaryType.CALL_SESSION_INFO,
          },
        ];

        setSummaryData(expandableData);
        setLoadingStatus((prev) => ({ ...prev, summary: false }));
      } catch (e) {
        navigate(`/${PrivateRoutes.DASHBOARD_V2}`, { replace: true });
        showUIToast({
          type: "error",
          text: "Error getting metrics, try again later.",
        });
      }
    };
    fetchMetrics(callSessionId);
  }, [callSessionId, currentUser?.userName]);

  const getSDRUsername = async (): Promise<string> => {
    const username = "Username";
    const { callSession, error } = await getCallSessionInfo({
      callSessionId,
    });

    if (!!error) {
      console.log(error);
      return username;
    }

    if (!callSession?.sdrId) return username;

    const { user, error: errorUser } = await getUserInfoAsync(
      callSession?.sdrId
    );

    if (errorUser) {
      console.log(errorUser);
      return username;
    }

    return user?.userName ?? username;
  };

  return loadingStatus.summary ? (
    <div
      style={{
        minHeight: "630px",
      }}
    >
      <Loader message="Loading summary, please wait..." />
    </div>
  ) : (
    <Container>
      <SubTitle>Call Session Info</SubTitle>
      <UICol justifyContent="center" gap={1.5}>
        {callSessionInfoData.map((item, index) => (
          <SummaryItem key={index} dataItem={item} />
        ))}
      </UICol>

      <SubTitle>Call Session Flow</SubTitle>
      <UICol justifyContent="center" gap={1.5}>
        {callSessionFlowData.map((item, index) => (
          <SummaryItem key={index} dataItem={item} />
        ))}
      </UICol>

      <SubTitle>Dispositions</SubTitle>
      <UICol justifyContent="center" gap={1.5}>
        {dispositionData.map((item, index) => (
          <SummaryItem key={index} dataItem={item} />
        ))}
      </UICol>
    </Container>
  );
};

export default SummaryContainer;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 2rem;
`;

const SubTitle = styled(UIText)`
  font-size: 1.5rem;
  margin: 1rem 0;
`;

import axios from 'axios';
import routes from '../../../../../src/constants/routes';
import { type SalesforceAccount } from './dto/get-salesforcer-accounts.dto';
import { type ApiResponse } from '../../../../../src/types/ApiResponse';

const getSalesforceAccounts = async (
  organizationId: string,
  userId: string,
): Promise<ApiResponse<SalesforceAccount[]>> => {
  try {
    const url = `${routes.get.salesforce.getLinkedAccounts}/${userId}/${organizationId}`;
    const salesforceAccounts = await axios.get<SalesforceAccount[]>(url);
    return { data: salesforceAccounts.data, success: true, status: salesforceAccounts.status };
  } catch (e: unknown) {
    return {
      success: false,
      error: { message: 'Error while getting salesforce accounts.' },
    };
  }
};

export default getSalesforceAccounts;

import routes from '@/src/constants/routes';
import axios from 'axios';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type DashboardResponse } from '@datasource/contacts-nest/dashboard/entities/dashboardResponse';

async function getDashboardDataAsync(
  sdrId: string,
  params: {
    page: number;
    pageLimit: number;
    searchQuery?: string;
    sdrIds?: string[];
    organizationIds?: string[];
  },
): Promise<ApiResponse<DashboardResponse>> {
  try {
    const dashboardResponse: ApiResponse<DashboardResponse> = await axios.get(
      `${routes.get.dashboard.getDashboardNest}/${sdrId}`,
      { params: { ...params } },
    );

    return { data: dashboardResponse.data, success: true };
  } catch {
    return { error: { message: `Could not retrieve dashboard data for sdr: ${sdrId}` }, success: false };
  }
}

export default getDashboardDataAsync;

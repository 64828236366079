export interface DefaultTheme {
  themeName: string;
  breakpoints: {
    xl: string;
    lg: string;
    md: string;
    sm: string;
  };
  colors: {
    lighterBlue: string;
    lightBlue: string;
    midBlue: string;
    darkBlue: string;
    orange: string;
    yellow: string;
    lightGray: string;
    darkGray: string;
    white: string;
    black: string;
    secondDarkGray: string;
    secondLightGray: string;
    danger: string;
    dangerDark: string;
  };
  maxWidth: string;
  statusColors: {
    primary: string;
    secondary: string;
    bg: string;
    callBtnBg: string;
    transferBtnBg: string;
    buttonTextBg: string;
    colorText: string;
  };
}

const breakpoints = {
  xl: "1360px",
  lg: "1024px",
  md: "768px",
  sm: "375px",
};

export const colors = {
  lighterBlue: "#2DC4ED",
  lightBlue: "#0095D4",
  midBlue: "#0A6B8C",
  darkBlue: "#0C548C",
  orange: "#CD6D47",
  yellow: "#DBA754",
  lightGray: "#EFEFEF",
  darkGray: "#A6A8AA",
  white: "#FFFFFF",
  black: "#000000",
  secondDarkGray: "#848789",
  secondLightGray: "#95979A",
  danger: "#FF0000",
  dangerDark: "#D0021B",
};

export const outreachColor = "#5952ff";
export const hubspotButtonColor = "#EAF0F6";
export const hubspotColor = "#2e475d";

const maxWidth = "1360px";

export const statusColors = {
  colorText: colors.white,
  primary: colors.lighterBlue,
  secondary: colors.lightBlue,
  bg: `linear-gradient(90deg, ${colors.lighterBlue}, ${colors.lightBlue});`,
  callBtnBg: `linear-gradient(90deg, ${colors.lightBlue}, ${colors.darkBlue});`,
  transferBtnBg: colors.lightGray,
  buttonTextBg: colors.darkGray,
};

const shared = {
  breakpoints,
  colors,
  maxWidth,
};

export const theme: DefaultTheme = {
  themeName: "",
  ...shared,
  statusColors,
  colors,
};

export const themeFree: DefaultTheme = {
  themeName: "free",
  ...shared,
  statusColors,
};

export const themeBusy: DefaultTheme = {
  themeName: "busy",
  ...shared,
  statusColors: {
    colorText: colors.white,
    primary: colors.lightBlue,
    secondary: colors.darkBlue,
    bg: `linear-gradient(90deg, ${colors.lightBlue}, ${colors.darkBlue});`,
    callBtnBg: `linear-gradient(90deg, ${colors.yellow}, ${colors.orange});`,
    transferBtnBg: `linear-gradient(90deg, ${colors.lighterBlue}, ${colors.lightBlue});`,
    buttonTextBg: colors.white,
  },
};

export const themePause: DefaultTheme = {
  themeName: "paused",
  ...shared,
  statusColors: {
    colorText: colors.black,
    primary: colors.lightGray,
    secondary: colors.darkGray,
    bg: colors.lightGray,
    callBtnBg: `linear-gradient(90deg, ${colors.yellow}, ${colors.orange});`,
    transferBtnBg: `linear-gradient(90deg, ${colors.lighterBlue}, ${colors.lightBlue});`,
    buttonTextBg: colors.white,
  },
};

import routes from "../../constants/routes";
import axios from "axios";
import {salesforceresposneContact} from "../../types/SalesforceTypes";

type ContactsProps = {
  contacts?: salesforceresposneContact[];
  error?: { message: string };
};


const getSalesforceContactsAsync = async (
  organizationId: string,
  userId: string,
  nonce: string,
  listId: string,
): Promise<ContactsProps> => {
  try {
    const salesforceAccounts = await axios.get<ContactsProps>(
      `${routes.get.salesforce.getContacts}/${organizationId}/${userId}/${nonce}/${listId}`
    );

    return { contacts: salesforceAccounts?.data.contacts };
  } catch (error) {
    return { error: { message: "Error while getting contacts" } };
  }
};

export default getSalesforceContactsAsync;

import routes from "../../constants/routes";
import axios from "axios";
import { SalesforceList } from "../../types/SalesforceTypes";

type ListsProps = {
    lists?: SalesforceList[];
    error?: { message: string };
};

const getListsFromSalesforceAsync = async (
    userId: string,
    orgId: string,
): Promise<ListsProps> => {
    try {
        const salesforce = await axios.get<SalesforceList[]>(
            `${routes.get.salesforce.getLists}/${orgId}/${userId}`
        );

        return { lists: salesforce?.data };
    } catch (error) {
        return { error: { message: "Error while getting sequences" } };
    }
};

export default getListsFromSalesforceAsync;

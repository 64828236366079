import axios from 'axios';
import routes from '@/src/constants/routes';
import { type ApiResponse } from '@/src/types/ApiResponse';
import { type SdrResponse } from '@datasource/edge/users/entities/user.entity';

async function getAllActiveSDRAsync(
  userId: string,
  userIds: string[] | null,
  organizationId: string | null,
  selectedOrgId: string | null,
): Promise<ApiResponse<SdrResponse[]>> {
  try {
    const sdrs: ApiResponse<SdrResponse[]> = await axios.get(`${routes.get.users.allActiveSdrsNest}/${userId}`, {
      params: {
        userIds,
        organizationId,
        selectedOrgId,
      },
    });
    return { data: sdrs.data, success: true };
  } catch {
    return { error: { message: 'Error getting active SDR' }, success: false };
  }
}
export default getAllActiveSDRAsync;

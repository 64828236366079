import { type IconName } from '@fortawesome/fontawesome-svg-core';

export interface SidebarItemData {
  anchor?: string;
  id: string;
  title: string;
  path: string;
  icon: IconName;
  restrictedToAdmin?: boolean;
  isRedirecting?: boolean;
  isHidden?: boolean;
  isBeta?: boolean;
}
export const SidebarData: SidebarItemData[] = [
  {
    id: 'home',
    title: 'Home',
    path: '/home',
    icon: 'house',
    isRedirecting: true,
    isHidden: false,
  },
  {
    id: 'contacts',
    title: 'Contacts',
    path: '/contacts',
    icon: 'address-book',
    isRedirecting: true,
    isHidden: false,
  },
  {
    id: 'voice',
    title: 'Voice',
    path: '/voice',
    icon: 'volume-high',
    isRedirecting: true,
    isHidden: false,
  },
  {
    id: 'intent',
    title: 'Intent',
    anchor: 'Script',
    path: '/script',
    icon: 'comments-dollar',
    isRedirecting: true,
    isHidden: false,
  },
  {
    id: 'dashboardTab-v2',
    title: 'Dashboard',
    path: '/dashboard-v2',
    icon: 'chart-pie',
    isRedirecting: true,
    isHidden: false,
  },
  {
    id: 'management',
    title: 'Management',
    path: '/management',
    icon: 'bars-progress',
    restrictedToAdmin: true,
    isRedirecting: true,
    isHidden: false,
  },
  {
    id: 'settings',
    title: 'Settings',
    path: '/settings',
    icon: 'gear',
    isRedirecting: true,
    isHidden: false,
  },
];

import React, { useContext, FC, ReactElement, useEffect } from "react";
import Layout from "../../components/shared/Layout";
import { featuresStore } from "../../components/app/FeaturesProvider";
import tabTitle from "../../utils/updateTitle";
import SettingsNav from "./Shared/Components/SettingsNav.component";
import {
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { UIText } from "../../core/ui/UIElements";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { IconLookup } from "@fortawesome/fontawesome-svg-core";
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { SETTINGS_MENU_OPTIONS } from "./SettingsOptionsSideBar";
import { selectFirstActiveFeature } from './Shared/utils/settings-menu.util';

const Settings: FC = () => {
  const { handleRoutesLogout, intervals, setIntervals, userSession } = useOutletContext() as any;
  const {
    featuresState: { outreach, userSettings, hubspot, hubspotCustom, callerId, accountSettings, salesforce },
  } = useContext(featuresStore);
  const { userSession: currentUser } = useOutletContext() as any; 

  const location = useLocation();

  const navigate = useNavigate();
  tabTitle("Tendril Connect | Settings");

  const isAnyFeatureFlagEnabled =
    outreach || userSettings || accountSettings || hubspot || hubspotCustom || callerId  || salesforce;

  const isExcludedPath = (path:string): boolean => {
      const regex = /^\/settings\/active-sessions\/[0-9a-fA-F]{24}$/;
      return regex.test(path);
  };

    
  useEffect(() => {

    if (isAnyFeatureFlagEnabled && (currentUser.isSuperAdmin && !isExcludedPath(location.pathname))) {
      navigate(selectFirstActiveFeature(SETTINGS_MENU_OPTIONS).link, {replace: true});
    }
  }, [isAnyFeatureFlagEnabled]);

  const updatedMenu = SETTINGS_MENU_OPTIONS.map(item => {
   switch (item.label) {
     case 'Account':
       item.isAvailable = userSettings;
       return item;
       case 'Account v2':
        item.isAvailable = accountSettings;
        return item;
     case 'Phone':
       item.isAvailable = callerId;
       return item;
     case 'Active Call Sessions':
       item.isAvailable = userSession.isSuperAdmin;
       return item;
     case 'Integrations':
       item.isAvailable = outreach || hubspot || hubspotCustom || salesforce;
       item.options?.map(i => {
         switch (i.label) {
           case 'Outreach':
             i.isAvailable = outreach;
             return i;
          case 'Salesforce':
             i.isAvailable = salesforce;
             return i;
           case 'Hubspot':
             i.isAvailable = hubspot;
             return i;
           case 'Hubspot Custom':
             i.isAvailable = hubspotCustom;
             return i;
         }
       })
       return item;
     default:
         return item
   }
  });

  return (
    <Layout
      sidebar
      handleLogout={handleRoutesLogout}
      intervals={intervals}
      setIntervals={setIntervals}
      isSettings={true}
      tabsIsEmpty={false}
    >
      <div className="relative flex-1  p-4 w-full h-full md:w-1/2 bg-white">
        <div className="flex h-full w-full">
          {isAnyFeatureFlagEnabled ? (
            <SettingsNav
              menuOptions={updatedMenu}
              component={<Outlet/>}
            />
          ) : (
            <NoFeaturesEnabled />
          )}
        </div>
      </div>
    </Layout>
  );
};

export const NoFeaturesEnabled = ({
  message,
}: {
  message?: string;
}): ReactElement => {
  return (
    <div className="w-full h-full grid place-items-center">
      <UIText className="text-center align-middle text-gray-400">
        <FA
          icon={faInfo as IconLookup}
          size="1x"
          className="text-app-blue-lighter border-solid border-2 border-app-blue-lighter rounded-full px-3 py-1.5 align-middle mr-2"
        />
        {message ??
          "Contact your sales representative to get the newest features."}
      </UIText>
    </div>
  );
};

export default Settings;